.top-campaign {
    flex-direction: column;
}

.top-campaign-text {
    font-size: 27px;
    font-weight: 600;
    font-family: "DM Sans";
    text-align: left;
    letter-spacing: -0.6px;
    color: #3B4758;
    opacity: 1;
}

.top-campaign-table {
    padding-top: 15px;
}

.view-all-button {
    display: flex;
    padding-top: 15px;
    justify-content: flex-end;
}

.top-creator-name {
    display: flex !important;
    align-items: center !important;
    column-gap: 4% !important;
}

.top-campaign-name {
    white-space: nowrap;
    width: 157px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 19px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #3B4758;
    opacity: 1;
    font-weight: 600;
    font-family: "DM Sans";
}

.top-campaign-category {
    white-space: nowrap;
    width: 157px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    letter-spacing: -0.31px;
    color: #586A84;
    opacity: 1;
    font-weight: 400;
    font-family: "DM Sans";
    font-size: 14px;
}

.top-campaign-row-text {
    font-size: 17px;
    font-weight: 600;
    font-family: "DM Sans";
    text-align: left;
    letter-spacing: 0.58px;
    color: #586A84;
    opacity: 1;
}

.top-campaign-tooltip {
    display: flex;
    align-items: center;
}

.campaign-name-column{
    display: flex;
    column-gap : 1rem;
}

/* ---------------------------Responsive---------------------------- */
@media screen and (max-width: 799px) {
    .top-campaign-text {
        margin-left: 1rem;
    }

    .top-creator-name {
        display: flex !important;
        flex-direction: column;
    }
    .campaign-name-column {
        display: flex;
        flex-direction: column;
    }
}
