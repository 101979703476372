.totalpost-container {
  display: flex;
  justify-content: space-between;
  padding: 28px 0px 28px 0px;

}

.totalpost-text {
  font-size: 20px;
  font-weight: 700;
  color: #434343;
}

.post-count {
  font-size: 16px;
  font-weight: 400;
  color: #878787;
}

.total-post {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.posts-container {
  font-family:
    Open Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 400;
}

.posts-wrapper {
  background-color: #fff;
  width: 100%;
  padding: 19px 64px 82px 32px;
}

.posts-header {
  display: flex;
  width: 100%;
  align-items: stretch;
  gap: 20px;
  color: #434343;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-title {
  font-size: 16px;
  margin: 0;
}

.metrics-nav {
  align-self: flex-start;
}

.metrics-list {
  display: flex;
  align-items: stretch;
  gap: 48px;
  font-size: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-divider {
  border: 0;
  border-top: 1px solid #434343;
  margin: 5px 0 0;
}

.post-item {
  border-bottom: 1px solid #aeaeae;
  padding: 12px 11px 12px 0;
  margin-top: 20px;
}

.post-main {
  display: flex;
  align-items: stretch;
  gap: 16px;
}

.user-info {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.profile-image {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.user-details {
  margin: auto 0;
}

.username {
  font-size: 14px;
  color: #464646;
  margin: 0;
}

.post-time {
  display: block;
  color: #878787;
  font-size: 12px;
  margin-top: 10px;
}

.story-badge {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f6efff;
  color: #65308f;
  font-size: 10px;
  padding: 0 16px;
  width: 56px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.like-count {
  display: flex;
  margin: auto 0;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #464646;
}

.like-icon {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.engagement-metrics {
  display: flex;
  margin: auto 0;
  align-items: flex-start;
  gap: 67px;
  font-size: 14px;
  color: #464646;
  flex-wrap: wrap;
  flex-grow: 1;
}

.metric-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.metric-icon {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.metric-count {
  align-self: stretch;
}

.engagement-rate {
  align-self: stretch;
}

.action-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

@media (max-width: 991px) {
  .posts-wrapper {
    max-width: 100%;
    padding: 19px 20px 82px;
  }

  .posts-header {
    max-width: 100%;
  }

  .username {
    white-space: normal;
  }

  .engagement-metrics {
    max-width: 100%;
    white-space: normal;
  }

  .metric-item {
    white-space: normal;
  }

  .metric-count {
    white-space: normal;
  }

  .engagement-rate {
    white-space: normal;
  }
}

.addpost-dialog {
  padding: 32px 24px;
}

.title-align {
  display: flex;
}

.button--secondary:hover {
  background-color: #eae6e6;
}

.button--primary:hover {
  background-color: #714596;
}

.insta-handle-container {
  display: flex;
  gap: 6px;
  width: 155px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard {
  border-radius: 0;
  font-family: Open Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.dashboard-container {
  background-color: #fff;
  width: 100%;
  padding: 24px 64px 9px 32px;
}

@media (max-width: 991px) {
  .dashboard-container {
    max-width: 100%;
    padding: 24px 20px 9px;
  }
}

.dashboard-header {
  display: flex;
  width: 100%;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .dashboard-header {
    max-width: 100%;
  }
}

.filters-wrapper {
  display: flex;
  align-items: stretch;
  gap: 36px;
  font-weight: 400;
}

.filter-dropdown {
  border-radius: 6px;
  border: 1px solid #8a8989;
  height: 40px;
  overflow: hidden;
  color: #434343;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.filter-dropdown:hover {
  border-color: #65308f;
}

.filter-dropdown.active {
  border-color: #65308f;
  box-shadow: 0 2px 8px rgba(101, 48, 143, 0.1);
  overflow: visible;
}

.filter-dropdown.active .dropdown-menu {
  height: auto;
  padding: 8px 0;
  border: 1px solid #65308f;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(101, 48, 143, 0.1);
}

.filter-dropdown.active .dropdown-icon {
  transform: rotate(180deg);
}

.dropdown-content {
  border-radius: 6px 6px 0 0;
  background-color: #fff;
  border-bottom: 1px solid #aeaeae;
  display: flex;
  min-height: 40px;
  width: 100%;
  padding: 4px 14px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.dropdown-header {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  outline: none;
}

.dropdown-header:focus {
  background-color: rgba(101, 48, 143, 0.05);
}

.dropdown-labels {
  flex: 1;
}

.dropdown-label {
  display: block;
  font-size: 10px;
}

.dropdown-value {
  font-size: 14px;
}

.dropdown-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  margin: auto 0;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.dropdown-menu {
  border-radius: 0 0 6px 6px;
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 0;
  padding: 0;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  position: absolute;
  left: -1px;
  right: -1px;
  z-index: 10;
}

.download-btn {
  border-radius: 6px;
  border: 1px solid #65308f;
  display: flex;
  padding: 8px 6px;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: #65308f;
  font-weight: 600;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  width: 141px;
}

.download-btn:hover:not(:disabled) {
  background: rgba(101, 48, 143, 0.1);
}

.download-btn:focus {
  box-shadow: 0 0 0 2px rgba(101, 48, 143, 0.2);
}

.download-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.download-btn.loading {
  color: transparent;
}

.download-btn.loading .download-icon {
  opacity: 0;
}

.download-btn.loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #65308f;
  border-radius: 50%;
  border-top-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
}

.download-btn.error {
  background: rgba(255, 0, 0, 0.1);
  border-color: #ff0000;
  color: #ff0000;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.download-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  flex-shrink: 0;
  transition: opacity 0.3s ease;
}

.performance-section {
  margin-top: 28px;
  min-height: 259px;
  width: 100%;
}

@media (max-width: 991px) {
  .performance-section {
    max-width: 100%;
  }
}

.section-title {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.metrics-grid {
  display: flex;
  margin-top: 28px;
  width: 100%;
  align-items: stretch;
  gap: 40px 56px;
}

.metrics-container {
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: center;
  gap: 40px 155px;
  flex-wrap: wrap;
  height: 100%;
  flex: 1;
}

.metric-card {
  align-self: stretch;
  display: flex;
  margin: auto 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  min-width: 137px;
  transition: all 0.3s ease;
  padding: 12px;
  border-radius: 8px;
  outline: none;
}



.metric-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.metric-main {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  color: #434343;
  font-weight: 600;
  white-space: nowrap;
}

.metric-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.metric-card:hover .metric-icon,
.metric-card:focus .metric-icon {
  transform: scale(1.1);
}

.metric-value {
  align-self: stretch;
  margin: auto 0;
}

.metric-title {
  color: #878787;
  font-size: 14px;
  font-weight: 400;
  margin: 4px 0 0;
}

.metric-title-wrapper {
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: center;
  gap: 4px;
}

.info-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: stretch;
  margin: auto 0;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  outline: none;
}

.info-icon:hover,
.info-icon:focus {
  transform: scale(1.2);
}

.metric-divider {
  border: 1px solid #8a8989;
  background-color: #8a8989;
  min-height: 1px;
  margin-top: 4px;
  width: 100%;
  transition: border-color 0.3s ease;
}

.metric-card:hover .metric-divider,
.metric-card:focus .metric-divider {
  border-color: #65308f;
  background-color: #65308f;
}

.metric-average {
  color: #878787;
  font-size: 12px;
  font-weight: 400;
  margin: 4px 0 0;
}

.metric-average strong {
  font-weight: 600;
  color: #434343;
}

.dashboard-divider {
  border: 1px solid #878787;
  background-color: #878787;
  height: 1px;
  margin-top: 28px;
}

.dashboard-footer {
  display: flex;
  margin-top: 28px;
  width: 100%;
  align-items: flex-start;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.post-count {
  width: 96px;
}

.post-count-title {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.post-count-value {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-top: 4px;
}

.add-link-btn {
  border-radius: 30px;
  background-color: #65308f;
  display: flex;
  min-height: 40px;
  padding: 8px 24px 8px 16px;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.add-link-btn:hover,
.add-link-btn:focus {
  background-color: #7b3bab;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(101, 48, 143, 0.2);
}

.add-link-btn:active {
  transform: translateY(0);
  box-shadow: none;
}

.add-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
  flex-shrink: 0;
}

@media (max-width: 991px) {

  .dashboard-container,
  .dashboard-header,
  .performance-section,
  .section-title,
  .metrics-grid,
  .metrics-container,
  .dashboard-divider,
  .dashboard-footer {
    max-width: 100%;
  }

  .add-link-btn {
    padding-right: 20px;
  }

  .metric-main {
    white-space: initial;
  }
}

.filter-group {
  display: flex;
  gap: 36px;
}

.relative.w-64 {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300mscubic -bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300mscubic -bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.creator-container {
  position: relative;
  width: 20rem;
}

.creator-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
}

.creator-dropdown {
  position: absolute;
  margin-top: 8px;
  width: 100%;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 10;
  padding: 12px;
}

.creator-input {
  margin-bottom: 8px;
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.creator-option {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.creator-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.creator-item:hover {
  background-color: #f3f3f3;
}

.creator-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.download-container {
  display: flex;
}