/* eslint-disable */
/*====================== START :: min-width: 320px and max-width; 479px ======================*/
@media (min-width: 320px ) and (max-width: 479px ) {
    
    /*Landing page*/
    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
    }
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #6A6A6A;
        text-align: center;
    }
    .LandingPage .FormSection .FormInfo {
        margin-left: 0px;
    }
    .mt-15mobi{
        margin-top: 15px;
    }
    .LandingPage .FormSection .ScheduleCall {
        font-size: 14px;
        line-height: 19px;
        width: 100%;
    }
    .LandingPage .FormSection {
        padding: 35px 0px 45px;
    }
    .LandingPage .ResultsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .BusinessLogoSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .LandingPage .CreatorFinderSection {
        padding: 40px 0px 0px;
    }
    .pl-10mobi{
        padding-left: 10px !important;
    }
    .LandingPage .BusinessLogoSection {
        padding: 25px 0px 50px;
    }
    .LandingPage .FormSection .MobiImg img{
        width: 55%;
    }
}

/*====================== END :: min-width: 320px and max-width; 479px ======================*/
/*====================== START :: min-width: 480px and max-width; 767px ======================*/
@media (min-width: 480px ) and (max-width: 767px ){
    /*Landing page*/
    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
    }
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #6A6A6A;
        text-align: center;
    }
    .LandingPage .FormSection .FormInfo {
        margin-left: 0px;
    }
    .mt-15mobi{
        margin-top: 15px;
    }
    .LandingPage .FormSection .ScheduleCall {
        font-size: 14px;
        line-height: 19px;
    }
    .LandingPage .FormSection {
        padding: 35px 0px 45px;
    }
    .LandingPage .ResultsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .BusinessLogoSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .LandingPage .CreatorFinderSection {
        padding: 40px 0px 0px;
    }
    .pl-10mobi{
        padding-left: 10px !important;
    }
    .LandingPage .BusinessLogoSection {
        padding: 25px 0px 50px;
    }
    .LandingPage .FormSection .MobiImg img{
        width: 55%;
    }
}

/*====================== END :: min-width: 480px and max-width: 767px ======================*/

/*====================== START :: min-width: 768px and max-width: 1024px ======================*/
@media (min-width: 768px ) and (max-width: 1024px ){
    
    /*Landing page*/
    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
    }
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #6A6A6A;
        text-align: center;
    }
    .LandingPage .FormSection .FormInfo {
        margin-left: 0px;
    }
    /* .mt-15mobi{
        margin-top: 15px;
    } */
    .LandingPage .FormSection .ScheduleCall {
        font-size: 14px;
        line-height: 19px;
    }
    .LandingPage .FormSection {
        padding: 35px 0px 45px;
    }
    .LandingPage .ResultsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .BusinessLogoSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .LandingPage .CreatorFinderSection {
        padding: 40px 0px 0px;
    }
    .pl-10mobi{
        padding-left: 10px !important;
    }
    .LandingPage .BusinessLogoSection {
        padding: 25px 0px 50px;
    }
    .LandingPage .FormSection .MobiImg img{
        width: 35%;
    }
    .mt15-mobi{
        margin-top: 15px;
    }
    }