
/* ------------sign-in -----------------------*/
/* responsive screen */
@media screen and  (max-width: 1024px)   {
  .left-signin {
    display: none;
  }
  
  .signin-paragraph.mobile {
    padding: 0px 33px 12px;
    font-size: 16px;
  }

  .signin-text.mobile {
    font-size: 1.5rem;
  }

  .signin-button-container {
    flex-direction: column;
  }

  .login-button {
    margin-top: 18px;
    align-self: stretch;
  }

  .right-signin.mobile {
    width: 100vw;
  }
} 


/* right-signin css */
.signin-container {
  display: flex;
  justify-content: center;
}

.signin-right-container {
  padding: 45px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.creator-logo {
  height: 100px;
}

.aaalogo {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.signin-text {
  font-weight: 600;
  font-size: 1.7rem;
}

.left-signin {
  background-color: #e563a4;
  width: 50vw;
  height: 100vh;
}

.privacy-policy-link-text {
  color: #e563a4;
  text-decoration: none;
}

.privacy-policy-link-text:hover {
  color: #e326a4;
  text-decoration: underline;
}

.right-signin {
  width: 50vw;
  height: 120%;
  justify-content: center;
}

.signinBtn {
  padding: 10px 20px;
  width: 200px;
  border: none;
  background-color: #e563a4;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 4px 6px rgba(0, 123, 255, 0.1);
  transition: all 0.3s ease;
}

.signinBtn:hover {
  background-color: #e326a4;
  box-shadow: 0px 6px 8px rgba(0, 123, 255, 0.2);
}

/* left-signin css */
.signin-left-content {
  color: #fff;
  background-color: #e563a4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signintitle {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
}

.signintitle-content {
  margin-top: 1rem;
  font-weight: 400;
  word-break: break-word;
  font-size: 1.25rem;
  width: 70%;
  text-align: center;
}

.signin-campain-container {
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
}

.signin-img {
  max-width: 100%;
  height: auto;
  width: 76%;
}

.signin-paragraph {
  text-align: center;
  padding: 0px 88px 12px;
  font-size: large;
}

.campain-text {
  margin-top: 0.7rem;
  font-weight: 400;
  font-size: 1rem;
}

.siginContainer {
  display: flex;
  justify-content: center;
}

.signin-left-footer {
  display: flex;
  gap: 50px;
  margin-top: 30px;
  align-items: center;
}

.signin-button-container {
  padding-top: 20px;
  display: flex;
  gap: 8%;
}

.login-button {
  border: 1px solid #00053d;
  color: #00053d;
  background-color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  object-fit: fill;
  border-radius: 35px;
  justify-content: center;
  padding: 13px 50px;
  font-size: 14px;
  font-weight: 500;
}

.login-button:hover {
  background-color: black;
  color: white;
}

.register-button {
  border: 1px solid #e563a4;
  color: #fff;
  background-color: #e563a4;
}

.register-button:hover {
  border: 1px solid #e326a4;
  background-color: #e326a4;
}

.modal-container {
  position: relative;
  z-index: 1000;
}

.dev-login-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 23px 0px 23px 0px;
  position: relative;
  z-index: 10;
}
.signin-backarrow {
  padding: 10px 10px;
}
