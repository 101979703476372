/* ----- Admin Table ---- */
.rotating {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------Main Container------------------ */
.instagram-logo {
  align-self: center;
  margin-right: 0.8em;
}

.filter-second-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.show-result-label {
  padding: 11px 14px;
  text-align: center;
  background: #f4f8fb;
  color: #8590a1;
  border-radius: 8px;
  width: 13em;
  height: fit-content;
  align-self: flex-end;
}

.show-result-label button {
  color: #8590a1;
}

.data-counts {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.influencer-container {
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 0px 0px 8px #A7C2D31A;
  border-radius: 8px;
  opacity: 1;
}

.influencer-content {
  padding: 20px 15px;
  border-radius: 8px;
  background: #fff;
}

.categories-label {
  padding: 12px 18px;
  background: #fff;
  border-radius: 8px 0 0 8px;
  color: #545454;
}

.followers {
  display: flex;
  gap: 10px;
}

.info-badge {
  position: relative;
  color: #849cb4;
  border: 1px solid #849cb4;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  margin: 0.3em;
  cursor: default;
  font-weight: 700;
}

.info-badge:hover {
  background-color: #849cb4;
  color: #fff;
}

.info-badge:hover .discovery-tooltip {
  display: block;
}

.discovery-tooltip {
  display: none;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #849cb4;
  color: #fff;
  font-family: inherit;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  width: 20em;
  text-align: center;
  font-size: 1.2em;
}

.discovery-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #849cb4 transparent transparent transparent;
  transform: translateX(-50%);
}

.nodata-found {
  font-size: x-large;
  display: flex;
  justify-content: center;
  color: gray;
  margin: 2em 0;
}

ul.influencers-list-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0;
}

.name-column {
  padding-left: 1.6em;
}

._button_m08jp_1._gray-outline_m08jp_112 > * {
  --bg: #fff;
  --border: 1px solid #bdbdbd;
  --color: #757575;
  --hoverColor: #616161;
  --hoverBorder: 1px solid #9e9e9e;
  --hoverBg: #eeeeee;
  --disabledBg: #eee;
  --disabledBorder: 1px solid #eee;
}

._button_m08jp_1 > *:disabled {
  border: var(--disabledBorder, var(--border)) !important;
  background: var(--disabledBg, var(--bg)) !important;
  color: var(--disabledColor, #9e9e9e) !important;
  cursor: not-allowed;
}

._button_m08jp_1 > * {
  display: inline-flex;
  height: var(--height, 36px);
  align-items: center;
  justify-content: center;
  padding-right: var(--padding-right, 1rem);
  padding-left: var(--padding-left, 1rem);
  border: var(--border, none);
  border-radius: var(--border-radius, 4px);
  background: var(--bg);
  color: var(--color) !important;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  text-decoration: var(--text-decoration, none);
  transition: background 0.15s;
  white-space: nowrap;
}

.hidden[data-v-d4e6e290] {
  visibility: hidden;
  display: none;
}

.influencer-grid {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 2px 10px #fafafa;
  position: relative;
  grid-template-columns: 80px 18% 15% 17% 18% 17% 1fr auto;
}

.influencer-grid:hover {
  background-color: #6ebdf14a;
}

.influencers-profile-outer-container {
  cursor: pointer;
}

._imgBlock_1ig5e_57 {
  padding: 1rem;
}

.influencers-profile-outer-container {
  padding: 1rem 0.2rem 1rem 1rem;
}

.element.style {
  background-image: url(https://imgigp.modash.io/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDaDVMq5CUHy%2BkcsdYgF20nDsuUwkRjFVav5M3TrTU9h7%2BxcZBxCJ2RhABotd8JjaVVGHFCcdShtZRKywU%2BQReCwgNlJUwVYqIiahjW32e%2BqWA%3D%3D);
}

.avatar-box {
  width: 3.125rem;
  height: 3.125rem;
}

.influencers-profile-container,
._img_19x11_1 {
  border-radius: 50%;
  background-image: url(/src/assets/profile.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.5px solid #7D8FA9;
}

._avatar_1ig5e_24 {
  width: 3.125rem;
  height: 3.125rem;
}

.influencers-data-outer-box {
  padding: 1rem;
}

.influencer-name-block {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.influencer-name-block .influencer-name {
  display: -webkit-box;
  overflow: hidden;
  max-height: 60px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 18px;
  line-height: 1.25;
  color: #586a84;
  font-weight: 600;
}

._verified_dbbth_91 {
  flex-shrink: 0;
}

.influencer-handle {
  color: #4aabed;
}

.influencer-handle:hover {
  color: #036ab0;
}

.influencer-data-block {
  color: #424242;
}

.influencer-data-block .influencer-handle-block {
  position: relative;
  font-size: 14px;
  text-decoration: none;
}

.influencer-data-block .influencer-handle-block:after {
  position: absolute;
  top: -0.3rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem;
  content: "";
}

.followers-data-block {
  min-width: 11rem;
}

.followers-outer-box {
  padding: 1rem;
  cursor: pointer;
}

.followers-outer-box .followers-count {
  font-size: 17px;
  line-height: 1.25;
  color: #849cb4;
  font-weight: 600;
}

.followers-outer-box .starts-at-count {
  color: #586a84;
}

.followers-outer-box .followers-count-highlight {
  color: #586a84;
}

.followers-outer-box .followers-label {
  color: #586a84;
  font-size: 14px;
  line-height: 1.25;
}

.followers-outer-box .per-pc-label {
  color: #586a84;
  font-weight: 100;
}

element.style {
  --17cc9de8: undefined;
}

._button_m08jp_1 {
  display: contents;
}

._button_m08jp_1._modash-green_m08jp_142 > * {
  --border: 1px solid #114b5f;
  --color: #114b5f;
  --hoverBg: #e8eff2;
}

._button_m08jp_1 > * {
  display: inline-flex;
  height: var(--height, 36px);
  align-items: center;
  justify-content: center;
  padding-right: var(--padding-right, 1rem);
  padding-left: var(--padding-left, 1rem);
  border: var(--border, none);
  border-radius: var(--border-radius, 4px);
  background: var(--bg);
  color: var(--color) !important;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  text-decoration: var(--text-decoration, none);
  transition: background 0.15s;
  white-space: nowrap;
}

._button_m08jp_1._modash-green-active_m08jp_147 > * {
  --border: 1px solid #114b5f;
  --bg: #e8eff2;
  --color: #114b5f;
}

.discovery-page-container {
  background: white;
  padding: 2rem;
}

/* -------------------- */
body {
  background: #fff;
  color: #333;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol;
  line-height: 1.5;
  word-wrap: break-word;
}

* {
  text-rendering: geometricPrecision;
}

*,
:before,
:after {
  box-sizing: border-box;
}

a,
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: inherit;
  outline: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

svg {
  outline: none;
}

.discovery-filters {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
}

.discovery-filters-mobile {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}

._root_1kdtt_1 {
  align-items: center;
  display: inline-flex;
  vertical-align: middle !important;
}

.filter-element-outer-box {
  width: 100%;
}

.vertical-divider {
  border-left: 1px solid #ddd;
  margin-right: 0.8rem;
  height: 4em;
}

.discovery-filter-column {
  display: flex;
  width: 100%;
}

._filterLabel_c8zr3_34 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.1rem;
}

._filterLabel_c8zr3_34 span {
  font-weight: 600;
}

.filter-outer-box {
  background-color: #eef0f4;
  padding: 4em 2rem;
}

.category-box {
  width: 100%;
}

._location_c8zr3_163 {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5em;
  width: 100%;
}

.engagement-col-header {
  display: inline-flex;
  align-items: center;
  vertical-align: middle !important;
}

._rowWithAlignment_c8zr3_1 {
  display: flex;
  align-items: baseline;
}

.trigger {
  display: block !important;
  flex: 1;
}

.tooltip-target[data-v-53579b4d] {
  height: 100%;
}

element.style {
  background-color: rgb(74, 171, 237);
}

._button_m08jp_1 {
  display: contents;
}

._button_m08jp_1._secondary_m08jp_64 > * {
  --bg: #fff;
  --border: 1px solid #bdbdbd;
  --color: #757575;
  --hoverColor: #616161;
  --hoverBorder: 1px solid #9e9e9e;
  --hoverBg: #eeeeee;
  --disabledBg: #eee;
  --disabledBorder: 1px solid #edf7fe;
}

._button_m08jp_1 > * {
  display: inline-flex;
  height: var(--height, 36px);
  align-items: center;
  justify-content: center;
  padding-right: var(--padding-right, 1rem);
  padding-left: var(--padding-left, 1rem);
  border: var(--border, none);
  border-radius: var(--border-radius, 4px);
  background: var(--bg);
  color: var(--color) !important;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  text-decoration: var(--text-decoration, none);
  transition: background 0.15s;
  white-space: nowrap;
}

._button_m08jp_1._main_m08jp_48 > * {
  --bg: #4aabed;
  --color: #fff;
  --hoverBg: #17527a;
  --disabledBg: #eee;
}

.influencers-inner-container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.creators-list-label {
  font-size: 20px;
  font-weight: 600;
  padding-left: 0.8em;
  color: #3b4758;
}

.creators-outer-container {
  padding: 35px;
}

.influencer-row-header {
  display: flex;
  align-items: center;
  width: fit-content;
  z-index: var(--z-index-discovery-has-results);
  opacity: 1;
  font-weight: 600;
}

.pagination-container {
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: #3b4758;
}

.pagination-container-mobile {
  align-items: center;
  flex-direction: column;
}

.custom-pagination-root .Mui-selected {
  background-color: #9c27b0 !important;
}

.application-action-button{
  display: flex;
}

@media only screen and (max-width: 1366px) and (min-width: 1366px) {
  .creators-outer-container {
    padding: 23px;
  }
}

@media screen and (max-width: 1366px) {
  .followers-data-block {
    min-width: 10rem;
  }
}
