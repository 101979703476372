.campaign-title {
    color: #464646;
    font-size: 20px;
    font-weight: 600;
  }
  .campaign-header {
    display: flex;
    padding: 0 64px 0 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
}
button.add-post-btn {
  color: #65308F;
  cursor: pointer;
}