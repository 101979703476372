/*====================== START :: min-width: 320px and max-width; 479px ======================*/
@media (min-width: 320px ) and (max-width: 479px ) {
    .no-mobi {
        display: none !important;
    }
    .show-mobi {
        display: block !important;
    }
    .mb-25mobi{
        margin-bottom: 25px;
    }
    .t-centermobi{
        text-align: center !important;
    }
    .OyeCreator_blog .img_sec .main_heading {
        font-size: 28px;
        line-height: 28px;
    }
    .OyeCreator_blog .img_sec .post_heading {
        font-size: 23px;
        line-height: 27px;
        font-weight: 700;
    }
    .OyeCreator_blog {
        padding-top: 30px;
    }
    .OyeCreator_blog .blog_info .bootcamp_info .content1 {
        font-size: 14px;
        font-weight: 800;
        line-height: 21px;
        color: #162020;
        padding-top: 15px;
    }
    .latest_Section .details .heading {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
    }
    .view_allBtn {
        font-size: 14px;
        line-height: 21px;
    }
    .featured_resources .details .heading, 
    .guides_section .details .heading, .data_reports .details .heading, 
    .marketing_insights .details .heading, .socialMedia_analytics .details .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .content,
    .featured_resources .latest_ImagesInfo .img_deatils .content,
    .guides_section .latest_ImagesInfo .img_deatils .content,
    .data_reports .latest_ImagesInfo .img_deatils .content,
    .marketing_insights .latest_ImagesInfo .img_deatils .content{
    font-size: 14px;
    line-height: 21px;
    }
    .buildGrow_section .content {
        padding-left: 0px;
        padding-right: 0px;
    }
    .buildGrow_section {
        background-color: #e563a41c;
        padding: 35px 0px 50px;
        margin-top: 40px;
    }
    .buildGrow_section .main_heading {
        font-size: 17px;
        line-height: 23px;
        font-weight: 700;
    }
    .buildGrow_section .info {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #162020;
    }
    .buildGrow_section .sprout_btn {
        background-color: #E563A4;
        color: #fff;
        border: none;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        padding: 10px 20px;
        border-radius: 6px;
        width: 100%;
    }
    .socialMedia_analytics .bootcamp_info .content1 {
        font-size: 12px;
        line-height: 18px;
        padding-top: 7px;
    }
    .socialMedia_analytics .date_info {
        font-size: 10px;
        line-height: 0px;
    }
    .socialMedia_analytics .bootcamp_Img img {
        width: 109%;
    }
    .topic_hubs .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .topic_hubs .link_names p {
        font-size: 14px;
    }

    .header .logo_img {
        background: #80808024;
        height: 62px;
        padding: 6px 25px 0px;
    }
    .header .logo_img img{
        width: 83px;
    }
    .mb-0mobi{
        margin-bottom: 0px !important;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .date_info img,
    .guides_section .latest_ImagesInfo .img_deatils .date_info img,
    .data_reports .latest_ImagesInfo .img_deatils .date_info img,
    .marketing_insights  .latest_ImagesInfo .img_deatils .date_info img{
        display: inline;
        padding: 0px 7px;
        font-size: 14px;
    }
    .date_info{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #162020;
    }
    .featured_resources, .guides_section, .data_reports, .marketing_insights, .topic_hubs, .socialMedia_analytics {
        padding-top: 0px;
    }
    .slick-dots li button:before{
        font-size: 28px !important;
    }
    .socialMedia_analytics .influencer_btn{
        width: unset;
    }
    .slick-dots {
        margin-bottom: 10px;
    }

    /*Blog details*/
    .BlogsDetails .BlogDetailsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
        text-align: left;
        margin: 0px;
        padding: 0px;
    }
    .BlogsDetails .BlogDetailsSection .content {
        font-size: 14px;
        line-height: 21px;
        padding: 20px 0px 0px;
        margin: 0px;
        text-align: left;
    }
    .BlogsDetails .SessionSection .SessionInfo {
        padding: 20px 0px;
    }
    .BlogsDetails .SessionSection .SocialIcons .ImgDiv {
        position: relative;
        padding: 0px 16px;
        display: inline-block;
    }
    .mt-20mobi{
        margin-top: 20px !important;
    }
    .BlogsDetails .SessionSection .name .UserName {
        font-size: 14px;
    }
    .BlogsDetails .SessionSection .name .Date {
        font-size: 12px;
        line-height: 18px;
    }
    .BlogsDetails .SessionContent .content {
        font-size: 14px;
        line-height: 23px;
    }
    .BlogsDetails .ButtonSection p {
        font-size: 20px;
        line-height: 30px;
        padding: 0px 0px 20px;
        margin: 0px;
    }
    .BlogsDetails .ButtonSection {
        padding: 35px 0px;
    }
    .BlogsDetails .ButtonSection .ClickBtn {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 0px;
        width: 100%;
    }
    .SocialMedia_Section {
        padding: 50px 0px;
    }
    .BlogsDetails .ReadNextSection {
        padding: 40px 0px;
    }
    .BlogsDetails .ReadNextSection .ReadNextDetails .MainHeading {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 30px;
    }
    .SocialMedia_Section .SocialMediaIcons {
        margin: 0px;
    }
    .SocialMedia_Section .TwitterIcn::before{
        display: none;
    }
    .BlogsDetails .SessionContent .MoreInfo {
        font-size: 16px;
    }
    .BlogsDetails .ReadNextSection .ReadImages img{
        width: 100%;
    }
   
    /*pricing details*/
    .PricingDetails .CreatorSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        padding: 0px;
        margin: 0px;
    }
    .PricingDetails .CreatorSection .StarImg {
        margin-bottom: -24%;
    }
    .PricingDetails .CreatorSection .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin: 0px;
        padding: 20px 0px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link.active {
        font-size: 14px;
        line-height: 21px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link {
        font-size: 14px;
        line-height: 21px;
    }
    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }
    .PricingDetails .GoodplansSection {
        margin-top: 20px;
    }
    .PricingDetails .GoodplansSection .ModelInfo {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .MainHeading {
        font-size: 26px;
        line-height: 34px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .content {
        font-size: 16px;
        line-height: 20px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 .TryBtn {
        font-size: 18px;
    }
    .PricingDetails .GoodplansSection .CreatorCount p {
        font-size: 14px;
        line-height: 30px;
    }
    .PricingDetails .ContactUs {
        background-color: #0F67EA;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0px 0px 40px;
        height: unset;
    }
    .PricingDetails .ContactUs img{
        width: 100%;
        margin-top: -60px;
        margin-left: 12px;
    }
    .PricingDetails .ContactUs p {
        font-size: 24px;
        line-height: 38px;
        padding: 20px 0px;
    }
    .PricingDetails .ContactUs .ContactBtn {
        font-size: 14px;
    }
    .PricingDetails .TrustedOrganizations .MainHeading {
        font-size: 16px;
        line-height: 24px;
    }
    .PricingDetails .TrustedOrganizations .OrganizationInfo {
        padding: 25px 0px;
    }
    .PricingDetails .QuestionsSection .MainHeading {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
    }
    .PricingDetails .QuestionsSection .accordion-button:not(.collapsed),
    .PricingDetails .QuestionsSection .accordion-button {
        font-size: 12px;
    }
    .PricingDetails .QuestionsSection .accordion-body {
        font-size: 12px;
        line-height: 21px;
    }
    .PricingDetails .QuestionsSection {
        padding: 40px 0px 50px;
    }
    .PricingDetails .GoodplansSection {
        padding: 50px 0px 15px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) {
        background-color: #F4F8FF;
        padding: 10px 10px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        border-bottom: 1px solid #DAE9FF;
        justify-content: left;
        color: #3C3C3C;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
    .FeaturesSection .FeaturesDetails .accordion-button::after {
        margin-left: auto;
        background-image: black;
    }
    /* .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed)::after{
        background-image: ;
    } */
    .PricingDetails .FeaturesSection .TryForFreeBtn {
        border: 2px solid #0F67EA;
        background-color: #fff;
        font-size: 12px;
        line-height: 28px;
        font-weight: 700;
        color: #0F67E9;
        padding: 1px 9px;
    }
    .sidebar {
        background-color: #fff;
        backdrop-filter: none;
        box-shadow: -3px 0 10px black;
    }
    .sidebar li {
        text-shadow: none;
        margin: 20px 0px 10px;
    }
    .TryBtn {
        width: 50%;
    }
    .DemoBtn {
        width: 50%;
        box-shadow: -3px 0 10px #0000002b;
    }
    .FeaturesSection .accordion-button:not(.collapsed) {
        font-size: 14px;
        line-height: 16px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed span{
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
        font-size: 14px;
        line-height: 16px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) span{
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .PricingDetails .FeaturesSection {
        margin-bottom: 40px;
    }
    .FeaturesSection .FeaturesDetails .accordion-body {
        padding: 0px 0px 0px;
    }
    .PricingDetails .FeaturesSection .FeaturesDetails table td {
        font-size: 14px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed {
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed{
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed span {
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
}

/*====================== END :: min-width: 320px and max-width; 479px ======================*/
/*====================== START :: min-width: 480px and max-width; 767px ======================*/
@media (min-width: 480px ) and (max-width: 767px ){
    .no-mobi {
        display: none !important;
    }
    .show-mobi {
        display: block !important;
    }
    .mb-25mobi{
        margin-bottom: 25px;
    }
    .t-centermobi{
        text-align: center !important;
    }
    .OyeCreator_blog .img_sec .main_heading {
        font-size: 28px;
        line-height: 28px;
    }
    .OyeCreator_blog .img_sec .post_heading {
        font-size: 23px;
        line-height: 27px;
        font-weight: 700;
    }
    .OyeCreator_blog {
        padding-top: 30px;
    }
    .OyeCreator_blog .blog_info .bootcamp_info .content1 {
        font-size: 14px;
        font-weight: 800;
        line-height: 21px;
        color: #162020;
        padding-top: 15px;
    }
    .latest_Section .details .heading {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
    }
    .view_allBtn {
        font-size: 14px;
        line-height: 21px;
    }
    .featured_resources .details .heading, 
    .guides_section .details .heading, .data_reports .details .heading, 
    .marketing_insights .details .heading, .socialMedia_analytics .details .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .content,
    .featured_resources .latest_ImagesInfo .img_deatils .content,
    .guides_section .latest_ImagesInfo .img_deatils .content,
    .data_reports .latest_ImagesInfo .img_deatils .content,
    .marketing_insights .latest_ImagesInfo .img_deatils .content{
    font-size: 14px;
    line-height: 21px;
    }
    .buildGrow_section .content {
        padding-left: 0px;
        padding-right: 0px;
    }
    .buildGrow_section {
        background-color: #e563a41c;
        padding: 35px 0px 50px;
        margin-top: 40px;
    }
    .buildGrow_section .main_heading {
        font-size: 17px;
        line-height: 23px;
        font-weight: 700;
    }
    .buildGrow_section .info {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #162020;
    }
    .buildGrow_section .sprout_btn {
        background-color: #E563A4;
        color: #fff;
        border: none;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        padding: 10px 20px;
        border-radius: 6px;
        width: 100%;
    }
    .socialMedia_analytics .influencer_btn {
        font-size: 12px;
    }
    .socialMedia_analytics .bootcamp_info .content1 {
        font-size: 12px;
        line-height: 18px;
        padding-top: 7px;
    }
    .socialMedia_analytics .date_info {
        font-size: 10px;
        line-height: 0px;
    }
    .socialMedia_analytics .bootcamp_Img img {
        width: 109%;
    }
    .topic_hubs .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .topic_hubs .link_names p {
        font-size: 14px;
    }
    .all_btn {
        font-size: 12px;
        padding: 4px 26px;
    }
    .influencer_btn {
        font-size: 14px;
        padding: 4px 26px;
    }
    .header .logo_img {
        background: #80808024;
        height: 62px;
        padding: 6px 25px 0px;
    }
    .header .logo_img img{
        width: 83px;
    }
    .mb-0mobi{
        margin-bottom: 0px !important;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .date_info img,
    .guides_section .latest_ImagesInfo .img_deatils .date_info img,
    .data_reports .latest_ImagesInfo .img_deatils .date_info img,
    .marketing_insights  .latest_ImagesInfo .img_deatils .date_info img{
        display: inline;
        padding: 0px 7px;
        font-size: 14px;
    }
    .date_info{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #162020;
    }
    .featured_resources, .guides_section, .data_reports, .marketing_insights, .topic_hubs, .socialMedia_analytics {
        padding-top: 0px;
    }
    .slick-dots li button:before{
        font-size: 28px !important;
    }
    .slick-dots {
        margin-bottom: 10px;
    }
    /*Blog details*/
    .BlogsDetails .BlogDetailsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
        text-align: left;
        margin: 0px;
        padding: 0px;
    }
    .BlogsDetails .BlogDetailsSection .content {
        font-size: 14px;
        line-height: 21px;
        padding: 20px 0px 0px;
        margin: 0px;
        text-align: left;
    }
    .BlogsDetails .SessionSection .SessionInfo {
        padding: 20px 0px;
    }
    .BlogsDetails .SessionSection .SocialIcons .ImgDiv {
        position: relative;
        padding: 0px 32px;
        display: inline-block;
    }
    .BlogsDetails .SessionContent .MoreInfo {
        font-size: 16px;
    }
    .mt-20mobi{
        margin-top: 20px !important;
    }
    .BlogsDetails .SessionSection .name .UserName {
        font-size: 14px;
    }
    .BlogsDetails .SessionSection .name .Date {
        font-size: 12px;
        line-height: 18px;
    }
    .BlogsDetails .SessionContent .content {
        font-size: 14px;
        line-height: 23px;
    }
    .BlogsDetails .ButtonSection p {
        font-size: 20px;
        line-height: 30px;
        padding: 0px 0px 20px;
        margin: 0px;
    }
    .BlogsDetails .ButtonSection {
        padding: 35px 0px;
    }
    .BlogsDetails .ButtonSection .ClickBtn {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 0px;
        width: 100%;
    }
    .SocialMedia_Section {
        padding: 50px 0px;
    }
    .BlogsDetails .ReadNextSection {
        padding: 40px 0px;
    }
    .BlogsDetails .ReadNextSection .ReadNextDetails .MainHeading {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 30px;
    }
    .SocialMedia_Section .SocialMediaIcons {
        margin: 0px;
    }
    .SocialMedia_Section .TwitterIcn::before{
        display: none;
    }
    .BlogsDetails .ReadNextSection .ReadImages img{
        width: 100%;
    }

    
    /*pricing details*/
    .PricingDetails .CreatorSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        padding: 0px;
        margin: 0px;
    }
    .PricingDetails .CreatorSection .StarImg {
        margin-bottom: -18%;
    }
    .PricingDetails .CreatorSection .content {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        margin: 0px;
        padding: 20px 0px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link.active {
        font-size: 16px;
        line-height: 21px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link {
        font-size: 16px;
        line-height: 21px;
    }
    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }
    .PricingDetails .GoodplansSection {
        margin-top: 20px;
    }
    .PricingDetails .GoodplansSection .ModelInfo {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .MainHeading {
        font-size: 26px;
        line-height: 34px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .content {
        font-size: 16px;
        line-height: 20px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 .TryBtn {
        font-size: 18px;
    }
    .PricingDetails .GoodplansSection .CreatorCount p {
        font-size: 14px;
        line-height: 30px;
    }
    .PricingDetails .ContactUs {
        background-color: #0F67EA;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0px 0px 40px;
        height: unset;
        margin-bottom: 0px;
    }
    .PricingDetails .ContactUs img{
        width: 100%;
        margin-top: -60px;
        margin-left: 12px;
    }
    .PricingDetails .ContactUs p {
        font-size: 24px;
        line-height: 38px;
        padding: 20px 5px;
    }
    .PricingDetails .ContactUs .ContactBtn {
        font-size: 14px;
    }
    .PricingDetails .TrustedOrganizations .MainHeading {
        font-size: 20px;
        line-height: 32px;
    }
    .PricingDetails .TrustedOrganizations .OrganizationInfo {
        padding: 25px 0px;
    }
    .PricingDetails .QuestionsSection .MainHeading {
        font-size: 24px;
        line-height: 26px;
        text-align: left;
    }
    .PricingDetails .QuestionsSection .accordion-button:not(.collapsed),
    .PricingDetails .QuestionsSection .accordion-button {
        font-size: 14px;
    }
    .PricingDetails .QuestionsSection .accordion-body {
        font-size: 14px;
        line-height: 21px;
    }
    .PricingDetails .QuestionsSection {
        padding: 40px 0px 50px;
    }
    .PricingDetails .GoodplansSection {
        padding: 50px 0px 15px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) {
        background-color: #F4F8FF;
        padding: 10px 10px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        border-bottom: 1px solid #DAE9FF;
        justify-content: left;
        color: #3C3C3C;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
    .PricingDetails .CreatorSection .StarImg img {
        width: 11%;
    }
    .FeaturesSection .FeaturesDetails .accordion-button::after {
        margin-left: auto;
        background-image: black;
    }
    /* .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed)::after{
        background-image: ;
    } */
    .PricingDetails .FeaturesSection .TryForFreeBtn {
        border: 2px solid #0F67EA;
        background-color: #fff;
        font-size: 12px;
        line-height: 28px;
        font-weight: 700;
        color: #0F67E9;
        padding: 1px 9px;
    }
    .sidebar {
        background-color: #fff;
        backdrop-filter: none;
        box-shadow: -3px 0 10px black;
    }
    .sidebar li {
        text-shadow: none;
        margin: 20px 0px 10px;
    }
    .TryBtn {
        width: 50%;
    }
    .DemoBtn {
        width: 50%;
        box-shadow: -3px 0 10px #0000002b;
    }
    .FeaturesSection .accordion-button:not(.collapsed) {
        font-size: 14px;
        line-height: 16px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed {
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed span{
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
        font-size: 14px;
        line-height: 16px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) span{
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .PricingDetails .FeaturesSection {
        margin-bottom: 40px;
    }
    .FeaturesSection .FeaturesDetails .accordion-body {
        padding: 0px 0px 0px;
    }
    .PricingDetails .FeaturesSection .FeaturesDetails table td {
        font-size: 14px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed {
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed{
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed span {
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
.pl-0mobi{
    padding-left: 0px !important;
}
}

/*====================== END :: min-width: 480px and max-width: 767px ======================*/

/*====================== START :: min-width: 768px and max-width: 1024px ======================*/
@media (min-width: 768px ) and (max-width: 1024px ){
    .no-mobi {
        display: none !important;
    }
    .show-mobi {
        display: block !important;
    }
    .mb-25mobi{
        margin-bottom: 25px;
    }
    .t-centermobi{
        text-align: center !important;
    }
    .OyeCreator_blog .img_sec .main_heading {
        font-size: 28px;
        line-height: 28px;
    }
    .OyeCreator_blog .img_sec .post_heading {
        font-size: 23px;
        line-height: 27px;
        font-weight: 700;
    }
    .OyeCreator_blog {
        padding-top: 30px;
    }
    .OyeCreator_blog .blog_info .bootcamp_info .content1 {
        font-size: 14px;
        font-weight: 800;
        line-height: 21px;
        color: #162020;
        padding-top: 15px;
    }
    .latest_Section .details .heading {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
    }
    .view_allBtn {
        font-size: 14px;
        line-height: 21px;
    }
    .featured_resources .details .heading, 
    .guides_section .details .heading, .data_reports .details .heading, 
    .marketing_insights .details .heading, .socialMedia_analytics .details .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .content,
    .featured_resources .latest_ImagesInfo .img_deatils .content,
    .guides_section .latest_ImagesInfo .img_deatils .content,
    .data_reports .latest_ImagesInfo .img_deatils .content,
    .marketing_insights .latest_ImagesInfo .img_deatils .content{
    font-size: 14px;
    line-height: 21px;
    }
    .buildGrow_section .content {
        padding-left: 0px;
        padding-right: 0px;
    }
    .buildGrow_section {
        background-color: #e563a41c;
        padding: 35px 0px 50px;
        margin-top: 40px;
    }
    .buildGrow_section .main_heading {
        font-size: 17px;
        line-height: 23px;
        font-weight: 700;
    }
    .buildGrow_section .info {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #162020;
    }
    .buildGrow_section .sprout_btn {
        background-color: #E563A4;
        color: #fff;
        border: none;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        padding: 15px 56px;
        border-radius: 6px;
    }
    .socialMedia_analytics .bootcamp_info .content1 {
        font-size: 12px;
        line-height: 18px;
        padding-top: 7px;
    }
    .socialMedia_analytics .date_info {
        font-size: 10px;
        line-height: 0px;
    }
    /* .socialMedia_analytics .bootcamp_Img img {
        width: 109%;
    } */
    .topic_hubs .heading {
        font-size: 16px;
        line-height: 18px;
    }
    .topic_hubs .link_names p {
        font-size: 14px;
    }
    .all_btn {
        font-size: 14px;
        padding: 6px 26px;
    }
    .influencer_btn {
        font-size: 14px;
        padding: 6px 26px;
    }
    .header .logo_img {
        background: #80808024;
        height: 62px;
        padding: 6px 25px 0px;
    }
    .header .logo_img img{
        width: 83px;
    }
    .mb-0mobi{
        margin-bottom: 0px !important;
    }
    .latest_Section .latest_ImagesInfo .img_deatils .date_info img,
    .guides_section .latest_ImagesInfo .img_deatils .date_info img,
    .data_reports .latest_ImagesInfo .img_deatils .date_info img,
    .marketing_insights  .latest_ImagesInfo .img_deatils .date_info img{
        display: inline;
        padding: 0px 7px;
        font-size: 14px;
    }
    .date_info{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #162020;
    }
    .featured_resources, .guides_section, .data_reports, .marketing_insights, .topic_hubs, .socialMedia_analytics {
        padding-top: 0px;
    }
    .slick-dots li button:before{
        font-size: 28px !important;
    }
    .socialMedia_analytics .influencer_btn{
        width: unset;
        font-size: 12px;
    }
    .socialMedia_analytics .all_btn{
        width: unset;
        font-size: 12px;
    }
    .slick-dots {
        margin-bottom: 10px;
    }
    /*Blog details*/
    .BlogsDetails .BlogDetailsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
        text-align: left;
        margin: 0px;
        padding: 0px;
    }
    .BlogsDetails .BlogDetailsSection .content {
        font-size: 14px;
        line-height: 21px;
        padding: 20px 0px 0px;
        margin: 0px;
        text-align: left;
    }
    .BlogsDetails .SessionSection .SessionInfo {
        padding: 20px 0px;
    }
    .BlogsDetails .SessionSection .SocialIcons .ImgDiv {
        position: relative;
        padding: 0px 16px;
        display: inline-block;
    }
    .mt-20mobi{
        margin-top: 20px !important;
    }
    .BlogsDetails .SessionSection .name .UserName {
        font-size: 14px;
    }
    .BlogsDetails .SessionSection .name .Date {
        font-size: 12px;
        line-height: 18px;
    }
    .BlogsDetails .SessionContent .content {
        font-size: 14px;
        line-height: 23px;
    }
    .BlogsDetails .ButtonSection p {
        font-size: 20px;
        line-height: 30px;
        padding: 0px 0px 20px;
        margin: 0px;
    }
    .BlogsDetails .ButtonSection {
        padding: 35px 0px;
    }
    .BlogsDetails .ButtonSection .ClickBtn {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 66px;
    }
    .SocialMedia_Section {
        padding: 50px 0px;
    }
    .BlogsDetails .ReadNextSection {
        padding: 40px 0px;
    }
    .BlogsDetails .ReadNextSection .ReadNextDetails .MainHeading {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 30px;
    }
    .SocialMedia_Section .SocialMediaIcons {
        margin: 0px;
    }
    .SocialMedia_Section .TwitterIcn::before{
        display: none;
    }
    .BlogsDetails .SessionContent .MoreInfo {
        font-size: 16px;
    }
    .BlogsDetails .ReadNextSection .ReadImages img{
        width: 100%;
    }

     /*pricing details*/
     .PricingDetails .CreatorSection .MainHeading {
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
        padding: 0px 90px;
        margin: 0px 30px;
    }
    .PricingDetails .CreatorSection .StarImg {
        margin-bottom: -18%;
    }
    .PricingDetails .CreatorSection .content {
        font-size: 20px;
        line-height: 23px;
        font-weight: 400;
        margin: 0px;
        padding: 20px 60px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link.active {
        font-size: 20px;
        line-height: 34px;
    }
    .PricingDetails .TabbingSection .nav-tabs .nav-link {
        font-size: 20px;
        line-height: 34px;
    }
    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }
    .PricingDetails .GoodplansSection {
        margin-top: 20px;
    }
    .PricingDetails .GoodplansSection .ModelInfo {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 {
        margin-bottom: 30px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .MainHeading {
        font-size: 28px;
        line-height: 38px;
    }
    .PricingDetails .GoodplansSection .ModelInfo .content {
        font-size: 18px;
        line-height: 26px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 .MainHeading {
        font-size: 26px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 .content {
        font-size: 18px;
        line-height: 26px;
    }
    .PricingDetails .GoodplansSection .ModelInfo1 .TryBtn {
        font-size: 18px;
    }
    .PricingDetails .GoodplansSection .CreatorCount p {
        font-size: 16px;
        line-height: 30px;
    }
    .PricingDetails .GoodplansSection .InfoLink {
        font-size: 14px;
    }
    .PricingDetails .ContactUs {
        background-color: #0F67EA;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0px 0px 40px;
        height: unset;
        margin-bottom: 0px;
    }
    .PricingDetails .ContactUs img{
        width: 100%;
        margin-top: -60px;
        margin-left: 37px;
    }
    .PricingDetails .ContactUs p {
        font-size: 26px;
        line-height: 38px;
        padding: 20px 56px 20px 0px;
    }
    .PricingDetails .ContactUs .ContactBtn {
        font-size: 18px;
    }
    .PricingDetails .TrustedOrganizations .MainHeading {
        font-size: 28px;
        line-height: 48px;
    }
    .PricingDetails .TrustedOrganizations .OrganizationInfo {
        padding: 25px 0px;
    }
    .PricingDetails .QuestionsSection .MainHeading {
        font-size: 28px;
        line-height: 30px;
        text-align: left;
    }
    .PricingDetails .QuestionsSection .accordion-button:not(.collapsed),
    .PricingDetails .QuestionsSection .accordion-button {
        font-size: 18px;
    }
    .PricingDetails .QuestionsSection .accordion-body {
        font-size: 18px;
        line-height: 26px;
    }
    .PricingDetails .QuestionsSection {
        padding: 40px 0px 50px;
    }
    .PricingDetails .GoodplansSection {
        padding: 50px 0px 15px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) {
        background-color: #F4F8FF;
        padding: 10px 10px;
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        border-bottom: 1px solid #DAE9FF;
        justify-content: left;
        color: #3C3C3C;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
    .PricingDetails .CreatorSection .StarImg img {
        width: 11%;
    }
    .FeaturesSection .FeaturesDetails .accordion-button::after {
        margin-left: auto;
        background-image: black;
    }
    .PricingDetails .FeaturesSection .TryForFreeBtn {
        font-size: 14px;
    }
    .sidebar {
        background-color: #fff;
        backdrop-filter: none;
        box-shadow: -3px 0 10px black;
    }
    .sidebar li {
        text-shadow: none;
        margin: 20px 0px 10px;
    }
    .TryBtn {
        width: 50%;
    }
    .DemoBtn {
        width: 50%;
        box-shadow: -3px 0 10px #0000002b;
    }
    .FeaturesSection .accordion-button:not(.collapsed) {
        font-size: 18px;
        line-height: 30px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed {
        justify-content: left;
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed span{
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
        font-size: 18px;
        line-height: 30px;
    }
    .FeaturesSection .FeaturesDetails .accordion-button:not(.collapsed) span{
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .PricingDetails .FeaturesSection {
        margin-bottom: 40px;
    }
    .FeaturesSection .FeaturesDetails .accordion-body {
        padding: 0px 0px 0px;
    }
    .PricingDetails .FeaturesSection .FeaturesDetails table td {
        font-size: 16px;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:last-of-type .accordion-button.collapsed {
        justify-content: left;
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed{
        justify-content: left;
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        padding: 10px 5px 10px 10px;
        background-color: #F4F8FF;
        border-bottom: 1px solid #DAE9FF;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .FeaturesSection .FeaturesDetails .accordion-item:first-of-type .accordion-button.collapsed::after {
        margin-left: auto;
    }
    .pl-0mobi{
        padding-left: 0px !important;
    }
    .pr-0mobi{
        padding-right: 0px !important;
    }

    }