.app-logo {
    width: 50%;
}

.drawer-header {
    padding: 3px 0px 0px 0px;
    display: flex;
    justify-content: space-around;
}

.selectedMenuItem {
    background-color: #e563a4 !important;
}

.creator-info-card {
    background-color: #f4f5f8;
    padding: 26px 40px 26px 40px;
    border-radius: 12px;
    display: flex;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.creator-info-card-mobile {
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    gap: 12px;
}

.top-campaign-row {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.top-campaign-row:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.profile-info {
    width: 270px;
}

.profile-info-mobile {
    display: flex;
    gap: 18px;
    align-items: center;
}

.user-profile-div {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.user-profile-pic {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-image: url('/src/assets/profile.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-pic-mobile {
    width: 3rem;
    height: 3rem;
}

.insta-profile-name {
    padding-top: 21px;
}

.dashboard-username {
    color: #e563a4;
    font-weight: 600;
    text-align: left;
    opacity: 1;
    font-size: 27px;
    font-family: "DM Sans";
    font-style: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboard-instahandle {
    color: #7d8fa9;
    font-size: 17px;
    font-weight: 600;
    font-family: "DM Sans";
    text-align: left;
    letter-spacing: 1px;
    opacity: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.connected-accounts {
    width: 290px;
    padding: 20px;
    border: 1px solid #d6cece;
    border-radius: 12px;
}

.connect-accounts-text {
    font-size: 20px;
    padding-bottom: 9px;
    text-align: left;
    color: #7D8FA9;
    opacity: 1;
    font-weight: 600;
    font-family: "DM Sans";
}

.dashboard-insta-icon {
    width: 53px;
}

.followers-div {
    display: flex;
    column-gap: 10%;
    align-items: center;
}

.progress-bar-icon {
    width: 38px;
}

.engagement-rate-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: left;
    color: #7D8FA9;
    opacity: 1;
    font-weight: 600;
    font-family: "DM Sans";
}

.engagement-rate {
    width: 260px;
    height: 106px;
    padding: 0px 11px;
}

.engagement-count-text {
    font-size: 22px;
    text-align: left;
    color: #7D8FA9;
    opacity: 1;
    font-weight: 600;
    font-family: "DM Sans";
}

.engagement-text {
    font-size: 12.99px;
    text-align: left;
    letter-spacing: -0.28px;
    color: #7D8FA9;
    opacity: 1;
    font-weight: 500;
    font-family: "DM Sans";
}

.insta-info-div {
    display: flex;
    column-gap: 4%;
}

.insta-info-div.engagement {
    margin-top: 12px;
}

.dashboard-divider {
    margin-top: 50px;
}

.insta-info-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.insta-info-mobile-inner-div {
    width: 50%;
}
