/* -----Header----- */
.landing-page-header {
    z-index: 999;
    background-color: #d6daff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    position: -webkit-sticky;
    position: sticky;
    top: 0%;
    left: 0%;
    right: 0%;
    background-color: rgb(214, 218, 255);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.landing-page-header-content {
    max-width: 1218px;
    padding-left: 24px;
    padding-right: 24px;
}

.landing-page-container {
    margin-left: auto;
    margin-right: auto;
}

.header-content-wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.aaa-logo-landing-page {
    /* height: 85px;
    width: 125px; */
    /* position: absolute;
    left: 20px; */
    width: 100px;
    cursor: pointer;
}

.header-left-col {
    align-items: center;
    display: flex;
    gap: 51px;
    font-weight:600;
    color: #00053d; 
}

.header-right-col-2 {
    justify-content: flex-end;
    display: flex;
    gap: 20px
}

.header-left-col .customers a,
.header-left-col .api a,
.header-left-col .pricing a {
    color: #00053d; 
    text-decoration: none;
}

.header-left-col .customers a:hover,
.header-left-col .api a:hover,
.header-left-col .pricing a:hover {
    color:blue;
    text-decoration: underline;
}

.buttons {
    grid-column-gap: 10px;
    display: flex;
}

.header-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page-cta-wrapper {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    display: flex;
}

.landing-page-button {
    letter-spacing: 1px;
    color: #fff;
    cursor: pointer;
    background-color: #e563a4;
    text-transform: capitalize;
    object-fit: fill;
    border-radius: 35px;
    justify-content: center;
    padding: 10px 24px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid transparent;
}

.demo-button {
    align-self: stretch;
    color:#56606E;
    background-color: #fff;
}

.landing-page-button:hover {
    text-decoration: none;
    box-shadow: 1px 1px 5px black;
}

.demo-button:hover {
    background-color: white;
    /* color: white; */
    box-shadow: 1px 1px 5px black;
    border-color: none;
}

.main-container {
    background-color: #f4f0fa;
    padding-top: 1px;
}

.container.hero.vertical.no-margin {
    padding-right: 20px;
}

.container.vertical.hero {
    max-width: 1200px;
    background-color: transparent;
}

.container.hero {
    padding-top: 10px;
    padding-bottom: 10px;
}

.hero-wrapper {
    grid-column-gap: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: stretch;
    position: relative;
}

.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.hero-text {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    position: relative;
}

.landing-page-title.hero {
    background-color: transparent;
}

.hero {
    background-color: var(--d6daff);
    justify-content: space-around;
    align-items: stretch;
}

.landing-page-title {
    color: var(--00053d);
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Navigo Bold, Arial, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.25;
}

.title-color {
    color: #6e7eec;
}

.paragraph-regular.semibold.left-aligned-mobile {
    padding-right: 40px;
}

.paragraph-regular.semibold {
    margin-bottom: 17px;
    font-weight: 500;
}

.paragraph-regular {
    color: var(--212121);
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
}

.hero-cta-wrapper {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    display: flex;
}

.container {
    max-width: 1200px;
    object-fit: fill;
    background-image: none;
    justify-content: center;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    position: relative;
}

.burger-menu-buttons {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    align-self: stretch;
    margin-bottom: 5px;
    display: flex;
    padding: 0px 12px;
}

.landing-page-button.full-size {
    align-self: stretch;
}

/* ------ start right container------- */

img.right-container-img {
    width: 100%;
}

img.landing-page-section-4-img {
    width: 28%;
}

.hero-image-div {
    object-position: 100% 0%;
    border-radius: 4px;
    flex: 0 .5 auto;
}

.landing-page-section-4-button {
    justify-content: center;
}

/* ------ end right container------- */

.container.vertical {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container.vertical.logos-container {
    margin-top: 20px;
}

.logo-grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-self: center;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 20px;
}

img.logo-img {
    width: 90px;
    height: 100px;
}

.logos-grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-self: center;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 0px;
}

/* landing page section 2 */

.container.hero.vertical.no-margin.landing-page-section-2 {
    padding-top: 60px;
}

.landing-page-section-2-container.landing-page-section-2-vertical {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing-page-section-2-container {
    object-fit: fill;
    background-image: none;
    justify-content: center;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    position: relative;
}

.landing-page-section-2-title {
    font-size: 47px;
    font-weight: 600;
}

.landing-page-section-2-sub-title {
    color: grey;
    font-size: 19px;
}

.landing-page-section-3-sub-title {
    padding: 0px;
    color: #5a5656;
}

.landing-page-section-2-paragraph-large {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
    color: #212121;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
}

/* landing page section 4 */
.landing-page-section-4 {
    border-top: 2px none #ebecff;
    background-color: white;
    margin-top: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    position: relative;
}

.landing-page-section-4-features-text-wrapper {
    grid-column-gap: 80px;
    grid-row-gap: 16px;
    flex-flow: column wrap;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    display: flex;
}

.landing-page-section-4-heading-2 {
    color: #00053d;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.25;
}

.landing-page-section-4-heading-2.landing-page-section-4-center {
    text-align: center;
}

.landing-page-section-4-features-body-wrapper {
    grid-column-gap: 80px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.landing-page-section-4-w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.landing-page-section-4-feature-section {
    flex-direction: column;
    align-items: center;
    display: flex;
}

.landing-page-section-4-tag.landing-page-section-4-color-discovery {
    font-size: 16px;
}

.landing-page-section-4-feature-section {
    flex-direction: column;
    align-items: center;
    display: flex;
}

.landing-page-section-4-tag {
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.landing-page-section-4-paragraph-regular {
    color: #212121;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.landing-page-section-4-paragraph-regular.landing-page-section-4-center {
    align-items: center;
    margin-bottom: 40px;
}

.landing-page-section-4-paragraph-regular.landing-page-section-4-center.landing-page-section-4-no-margin {
    margin-bottom: 10px;
}

#w-node-ba63f321-84d5-e570-8726-82519f761d6c-8c7c55df {
    grid-area: span 1 / span 3 / span 1 / span 3;
}

.landing-page-section-4-buttons.landing-page-section-4-top-margin-40 {
    margin-top: 0;
}

/* landing page ask us section  */
.landing-ask-us-section-wrapper {
    margin-top: 40px;
}

.landing-ask-us-section-item {
    border-bottom: 2px solid #212121;
    padding-top: 20px;
    padding-bottom: 20px;
}

.landing-ask-us-section-item-trigger {
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.landing-ask-us-section-heading {
    color: #00053d;
    text-align: left;
    align-self: stretch;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
}

.landing-ask-us-section-icon-wrapper {
    padding-left: 20px;
}

.landing-ask-us-section-paragraph-regular {
    color: var(--212121);
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.landing-ask-us-section-item-content {
    overflow: hidden;
}

.landing-ask-us-section-paragraph-regular.landing-ask-us-section-left-align {
    min-width: 200px;
    margin-bottom: 40px;
}

.landing-ask-us-section-item.landing-ask-us-section-last {
    border: 1px #000;
}
.nav-items {
    display: flex;
    gap: 4rem;
}