body {
  font-family: Arial, sans-serif;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: none;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  height: fit-content;
  width: fit-content;
  border-radius: 10px;
  backdrop-filter: blur(2px);
}

.loginBtn {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  background-image: linear-gradient(to bottom, #007bff, #0056b3);
  color: white;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 4px 6px rgba(0, 123, 255, 0.1);
  transition: all 0.3s ease;
}

.loginBtn:hover {
  background-color: #0056b3;
  background-image: linear-gradient(to bottom, #0056b3, #00438b);
  box-shadow: 0px 6px 8px rgba(0, 123, 255, 0.2);
}

.back-arrow-login-container {
  background-color: white;
  width           : 3vw;
  height          : 6vh;
  border-radius   : 50px;
  display         : flex;
  margin-top      : 1rem;
  margin-left     : 1rem;
  color           : #e563a4;
  align-items     : center;
  justify-content : center;
  cursor          : pointer;
  transition      : background-color 0.3s ease;
}

.back-arrow-login-container:hover {
  background-color: rgba(255, 255, 255, 0.926);
}

.left-text-content {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

/* ------------start sign-in responsive-----------------------*/
@media (max-width: 1024px) {
  .left-login-container.mobile {
    display: none;
  }
  .custom-textfield.MuiFormControl-root.MuiTextField-root {
    padding: 0px 10px 0px 10px;
  }
  .custom-manager {
    padding: 0px 0px 0px 12px;
  }
  .custom-radio.MuiFormControl-root {
    padding: 0px 0px 0px 12px;
  } 
  .categories-dropdown {
    width: 378px;
    padding: 0px 10px;
  }
  .city-dropdown{
    width: 378px;
    padding: 0px 10px;
  }
  .manager-details {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  padding-left: 15px;
}
  .oyecreators-logo {
    display: flex;
    justify-content: center;
    padding-left: 15px;
  }
}
/* ------------end sign-in responsive-----------------------*/

.MuiFormControl-root-MuiTextField-root {
  padding: 0px 10px;
  width: 95%;
}
/* right-login css */
.login-container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.left-login-container {
  background-color: #e563a4;
  width: 50vw;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.right-login {
  width: 50vw;
  justify-content: center;
  display: flex;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.heading {
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
}

img.aaa-modash-logo {
  height: 45px;
}

.creator-login-logo {
  height: 66px;
}

.oyecreators-logo {
  display: flex;
  justify-content: center;
}
.logMeUp {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
}
.log-me-up-button {
  width: 378px;
  height: 40px;
  font-size: 20px;
  line-height: 30px;
  background-color: #e563a4;
  color: white;
  border: none;
  border-radius: 5px;
}

.log-me-up-disable {
  width: 378px;
  height: 40px;
  font-size: 20px;
  line-height: 30px;
  background-color: #e791bc;
  color: white;
  border: none;
  border-radius: 5px;
}

.back-button {
  margin-top: 5%;
  width: 378px;
  height: 40px;
  font-size: 20px;
  line-height: 30px;
  border: 1px solid #00053d;
  color: #00053d;
  background-color: #fff;
  border-radius: 5px;
}

.back-button:hover {
  background-color: black;
  color: white;
}

.log-me-up-button:hover {
  background-color: #e326a4;
}

.login {
  display: inline-flex;
  cursor: pointer;
  font-weight: 600;
  padding: 0;
  color: #4aabed;
  padding-left: 0.5rem;
  text-decoration: none;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* left-login css */

.title {
  text-align: center;
  margin-top: 7rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3.375rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.title-content {
  margin-top: 1rem;
  font-weight: 400;
  word-break: break-word;
  font-size: 1.25rem;
  width: 70%;
  min-width: 400px;
  text-align: center;
  max-width: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.campain-container {
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
}

.signin-img {
  max-width: 100%;
  height: auto;
  width: 76%;
}

.campain-text {
  margin-top: 0.7rem;
  font-weight: 400;
  font-size: 1rem;
}

.login-left-footer {
  display: flex;
  gap: 50px;
  margin-top: 305px;
  align-items: center;
}

/* input fields */
.input-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.infoIcon {
  display: flex;
  justify-content: flex-end;

}
.info {
  display: flex;
  align-items: center;
}
.infoIconStyle{
  font-size: small;
}
.manager-details {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.custom-manager {
  display: flex;
  align-items: center;
}

div.input-field {
  padding: 0px 0px 0px 40px;
}
