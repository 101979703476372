.landing-page-footer {
    background-color: #00053d;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    position: relative;
}

.landing-page-footer-nav {
    display: block;
}

.landing-page-footer-grid {
    display: flex;
    justify-content: space-evenly;
    grid-column-gap: 30px;
}

.landing-page-footer-heading.landing-page-footer-header {
    color: #fafafa;
}

.landing-page-footer-heading {
    color: #00053d;
    text-align: left;
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.landing-page-footer-item {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.landing-page-footer-color {
    color: #f7f7ff;
}

.landing-page-footer-social-media-block:hover {
    opacity: .7;
}

.landing-page-footer-social-media-block-youtube:hover {
    opacity: .7;
}

.landing-page-footer-social-media-block {
    width: 55%;
}

.landing-page-footer-social-media-block-youtube {
    width: 67%;
}

.landing-page-footer-contact-us {
    opacity: .6;
    text-align: start;
    display: flex;
    margin-top: 60px;
}

.landing-page-footer-paragraph-small.landing-page-footer {
    color: rgba(250, 250, 250, .4);
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px 0;
    font-weight: 400;
}

.landing-page-footer-paragraph-small {
    color: #757575;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.landing-page-footer-paragraph-small.landing-page-footer {
    padding: 50px 10px 0px 10px;
}

.main-link.landing-page-footer {
    color: rgba(250, 250, 250, .4);
    text-align: left;
    justify-content: flex-start;
    padding: 8px 0;
    text-decoration: underline;
}

.main-link:hover {
    color: rgba(250, 250, 250, .8);
}

.main-link {
    color: #4353ff;
    text-align: left;
    font-weight: 600;
    text-decoration: underline;
    display: flex;
}

.main-link.landing-page-footer-small {
    color: rgba(250, 250, 250, .4);
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400;
}

.landing-page-footer-devloped-by {
    display: flex;
    gap: 37px;
    text-align: start;
    padding-top: 18px;
}

.landing-page-footer-devloped-by-text {
    color: rgba(250, 250, 250, .4);
    align-self: center;
    font-weight: 600;
}

.landing-page-footer-devloped-by-text:hover {
    color: rgba(250, 250, 250, .8);
}

.landing-page-footer-devloped-by-image {
    display: inline-flex;
}

.policy-header {
    color: #11197c;
}
.landing-page-footer-lion-logo {
    display: flex;
    align-items: center;
}
img.lion-logo {
    width: 80px;
}
.landing-page-footer-company-details-list {
    display: flex;
    color: rgba(250, 250, 250, .4);
    font-size: 14px;

}
.company-details-heading {
    display: flex;
    color: white;
}
.email-link {
    color:rgba(250, 250, 250, .4); 
    padding-right: 15px;
}
.email-link:hover{
    color:rgba(250, 250, 250, .8);
}
.landing-page-footer-company-details {
    margin-top: 10px;
}
.company-details-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.TTL-style{
        font-weight: bold;
}
.whatsapp-icon{
    color:rgba(250, 250, 250, .4);  
    padding-left: 2px;
}
.whatsapp-icon:hover{
    color:rgba(250, 250, 250, .8);
   
}