/* eslint-disable */
/*Landing Page*/
.LandingPage {
    padding-top: 1px;
}

.LandingPage .ResultsSection {
    padding: 40px 0px;
}

.LandingPage .ResultsSection .MainHeading {
    font-size    : 38px;
    line-height  : 45px;
    font-weight  : 600;
    text-align   : center;
    margin-bottom: 40px;
    color        : #333333;
}

.LandingPage .ResultsSection .NoOfLikes {
    border    : 1px solid #ECF4FF;
    box-shadow: 6px 0px 12px 0px #EEEEEE4D;
    text-align: center;
    padding   : 10px 5px;
}

.LandingPage .ResultsSection .views {
    font-size  : 18px;
    line-height: 30px;
    font-weight: 400;
    color      : #000;
}

.LandingPage .ResultsSection .NoOfViews {
    font-size  : 24px;
    line-height: 32px;
    font-weight: 700;
    color      : #1B1C1E;
}

.LandingPage .BusinessLogoSection {
    padding: 100px 0px 50px;
}

.LandingPage .BusinessLogoSection .MainHeading {
    font-size    : 38px;
    line-height  : 45px;
    font-weight  : 600;
    text-align   : center;
    margin-bottom: 50px;
    color        : #333333;
}

.LandingPage .BusinessLogoSection .CaseStudiesBtn {
    background-color: #0F67EA;
    color           : #fff;
    font-size       : 16px;
    line-height     : 28px;
    font-weight     : 700;
    border          : none;
    border-radius   : 8px;
    padding         : 8px 30px;
    margin-top      : 40px;
}

.LandingPage .CreatorFinderSection {
    padding: 50px 0px;
}

.LandingPage .CreatorFinderSection .ImgInfo {
    padding-top: 30px;
}

.LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
    font-size  : 38px;
    line-height: 45px;
    font-weight: 600;
    color      : #333333;
}

.LandingPage .CreatorFinderSection .ImgInfo .content {
    font-size  : 18px;
    line-height: 30px;
    font-weight: 400;
    color      : #162020;
}

.LandingPage .CreatorFinderSection img {
    width: 100%;
}

.LandingPage .FormSection {
    background-color: #F4F0FA;
    padding         : 75px 0px;
}

.LandingPage .FormSection .DiscoverCreator .MainHeading {
    font-size  : 60px;
    line-height: 72px;
    font-weight: 800;
    color      : #333333;
}

.LandingPage .FormSection .DiscoverCreator .MainHeading span {
    color: #6E7EEC;
}

.LandingPage .FormSection .DiscoverCreator .content {
    font-size    : 18px;
    line-height  : 30px;
    font-weight  : 400;
    color        : #333333;
    margin-bottom: 0px;
    padding-right: 20px;
}

.LandingPage .FormSection .FormInfo {
    border          : 2px solid #EBEBEB;
    background-color: #fff;
    border-radius   : 16px;
    padding         : 30px 25px;
    margin-left     : 30px;
}

.LandingPage .FormSection .FormInfo label {
    font-size  : 13px;
    line-height: 20px;
    font-weight: 500;
    color      : #000;
}

.LandingPage .FormSection .FormInfo .form-control {
    border          : 1px solid #CBD6E2;
    background-color: #F5F8FA;
    border-radius   : 3px;
    height          : 40px;
}

.LandingPage .FormSection .ScheduleCall {
    background-color: #0F67EA;
    color           : #fff;
    font-size       : 16px;
    line-height     : 28px;
    font-weight     : 700;
    border          : none;
    border-radius   : 8px;
    padding         : 8px 30px;
    margin          : 40px 0px 20px;
}

.form-control:focus {
    box-shadow: none !important;
}

.col-md-2 {
    width: 14vw;
}

.slider1 {
    display    : flex;
    align-items: flex-end;
    gap        : 1rem;
}
.logo-images {
    width: 8vw;
}
.logo-images-logo{
    width: 4vw;
}
.logo-business {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
.ec-logo-images {
    width: 7vw;
}
.ec-logo-images-logo {
    width: 4vw;
}
.ec-business-logo {
    display: flex;
    gap: 2rem;
}
.ec-logo-business-mobile-view {
    display: flex;
    gap: 2rem;
    align-items: center;
}
.setu-logo {
    width: 9vw;
}
.ranka-logo {
    width: 8vw;
}
.row {
    display: flex;
    align-items: center;
}
.row-discover {
    display: flex;
    align-items: flex-start;
}

@media (min-width: 320px) and (max-width: 479px) {
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size  : 14px;
        line-height: 21px;
        font-weight: 400;
        color      : #6A6A6A;
        text-align : center;
    }

    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size  : 28px;
        line-height: 36px;
        font-weight: 700;
        text-align : center;
    }

    .LandingPage {
        padding-top: 1px;
    }

    .LandingPage .FormSection .FormInfo {
        margin-left: 0px;
    }

    .LandingPage .BusinessLogoSection .MainHeading {
        font-size  : 28px;
        line-height: 36px;
    }

    .LandingPage .ResultsSection .MainHeading {
        font-size  : 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .LandingPage .FormSection {
        background-color: #F4F0FA;
        padding: 35px 0px;
    }
    .ec-logo-business-mobile-view {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
    }
    .ec-logo-images {
        width: 16vw;
    }
    .setu-logo {
        width: 16vw;
    }
    .ranka-logo {
        width: 16vw;
    }
    .ec-logo-images-logo {
        width: 8vw;
    }
    .ec-business-logo {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .LandingPage .BusinessLogoSection {
        padding: 25px 0px 0px;
    }
    .LandingPage .CreatorFinderSection {
        padding: 40px 0px 0px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
    }
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #6A6A6A;
        text-align: center;
    }
    .LandingPage .FormSection .FormInfo {
        margin-left: 0px;
    }
    .LandingPage .ResultsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .BusinessLogoSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .LandingPage .FormSection .DiscoverCreator .MainHeading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
    }
    .LandingPage .FormSection .DiscoverCreator .content {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #6A6A6A;
        text-align: center;
    }
    .LandingPage .ResultsSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .BusinessLogoSection .MainHeading {
        font-size: 28px;
        line-height: 36px;
    }
    .LandingPage .CreatorFinderSection .ImgInfo .MainHeading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
}