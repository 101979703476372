.header-report {
  border-radius: 0;
}

.header-report-wrapper {
  background-color: #f6efff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 30px;
}

.report-avatar-box {
  width: 48px;
  height: 48px;
}

.report-influencers-profile-container,
._img_19x11_1 {
  border-radius: 50%;
  background-image: url(/src/assets/profile.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.5px solid #7D8FA9;
}

@media (max-width: 991px) {
  .header-report-wrapper {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.action-bar {
  display: flex;
  width: 100%;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .action-bar {
    max-width: 100%;
  }
}

.icon-wrapper {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  min-height: 24px;
  padding: 4px;
  align-items: center;
  gap: 10px;
  width: 24px;
  justify-content: flex-start;
  height: 24px;
  margin: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.icon-wrapper:hover {
  background-color: rgba(246, 239, 255, 0.9);
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto;
}

.status-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  flex-shrink: 0;
}

.content-container {
  align-self: center;
  display: flex;
  width: 100%;
  align-items: stretch;
  gap: 20px;
  font-family:
    Open Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10px;
}

@media (max-width: 991px) {
  .content-container {
    max-width: 100%;
  }
}

.report-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  color: #434343;
  justify-content: flex-start;

}


.report-header:hover .report-title {
  color: #2a2a2a;
}

.profile-image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  flex-shrink: 0;
}

.report-info {
  min-width: 240px;
  width: 319px;
}

.title-container {
  max-width: 100%;
  width: 312px;
}

.report-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.report-handle {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0;
}

.metadata {
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  justify-content: flex-start;
}

.creator-count {
  align-self: stretch;
  display: flex;
  margin: auto 0;
  align-items: flex-end;
  gap: 6px;
  justify-content: flex-start;
  padding: 2px 4px;
  border-radius: 4px;
}





.metadata-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 14px;
  flex-shrink: 0;
  transition: transform 0.2s ease;
}

.separator {
  align-self: stretch;
  margin: auto 0;
}

.report-dates {
  align-self: stretch;
  display: flex;
  margin: auto 0;
  align-items: flex-end;
  gap: 6px;
  justify-content: flex-start;
  padding: 2px 4px;
  border-radius: 4px;
}

.report-dates:hover {
  background-color: rgba(246, 239, 255, 0.6);
}

.report-dates:hover .metadata-icon {
  transform: scale(1.1);
}

.update-info {
  color: #464646;
  font-size: 8px;
  font-weight: 400;
  align-self: flex-end;
  margin-top: 81px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .update-info {
    margin-top: 40px;
  }
}

.editicon-img {
  cursor: pointer;
}