body {
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol;
}

.data-loading {
  width: 100%;
}

.campaign-list-options .new-campaign, .followers-data-block .ask-for-price-btn {
  background-color: #e563a4;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1em;
  font-family: inherit;
  padding: 0.2rem 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.followers-data-block .ask-for-price-btn {
  margin-top: auto;
}

.campaign-list-options .new-campaign:hover, .followers-data-block .ask-for-price-btn:hover {
  background-color: #ee3692;
}

.middle-tabs {
  margin-right: 5em;
}
.campaign-list-row {
  display: flex;
  margin-top: 1em;
}
.campaign-list-row .first-element {
  width: 22em;
}
.campaign-list-row label {
  font-weight: 500;
}
.campaign-page,
.campaign-details {
  width: 100%;
}
.campaign-page h1 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  font-family: inherit;
}
.campaign-list-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
}
.campaign-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: -0.5em;
}
.campaign-list-hr {
  height: 2px;
  --un-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--un-bg-opacity));
  margin-top: 0.4em;
}
.active-option {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 3px solid #000;
  margin-bottom: -0.5em;
}

.active-tab {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid #000;
}

.tab,
.deactive-option {
  cursor: pointer;
}
.campaign-list-box {
  box-shadow: 2px 2px 8px #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin: 2em 0;
  cursor: pointer;
}
.campaigns-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* padding: 0rem 1.5rem 1.5rem 1.5rem; */
  padding: 1.5rem;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
}

.get-campaigns-options {
  display: flex;
  justify-content: space-between;
  width: 17em;
  margin-top: 2em;
  font-size: 1.2em;
}

.insta-info-title .campaign-information-badge {
  margin: 0.1em 0 0 0.5em;
}
.list-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list-title {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}
.campaign-name {
  flex: 1;
  color: #000;
  font-size: 1.25rem;
  font-weight: 600;
}

.toggle-section,
.keywords-container,
.num-creators,
.campaign-remark-container {
  margin-top: 2.5em;
}

.option-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30em;
}

.asterisk {
  color: #ff1010;
  margin-left: 0.2em;
}

.travel-allowance,
.production-available {
  margin: 2.5em 0;
}

.campain-start-date {
  color: #616161;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
}
.list-insta-content {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
}
.list-insta-content .engagement-rate {
  display: flex;
}
.insta-content-first-row,
.insta-content-second-row {
  display: flex;
}
.insta-content-inner-div {
  margin-left: 7em;
}
.insta-info-title {
  margin-top: 0.5em;
  color: #757575;
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.3125em;
}
.count {
  display: flex;
  justify-content: center;
  font-size: 1.25em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875em;
}
.track-campaign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
}
.left-track,
.right-track {
  display: flex;
  align-items: center;
}
.badge {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
}
.custom-badge {
  background-color: #114b5f;
  color: #fff;
}
.disable-badge {
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
}
.information-badge {
  position: relative;
  color: #212121;
  border: 1px solid #212121;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  margin: 0.3em 0 0 0.5em;
  cursor: default;
  font-weight: 700;
}
.information-badge:hover {
  background-color: #212121;
  color: #fff;
}
.information-badge:hover .tooltip {
  display: block;
}
.tooltip {
  display: none;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  font-family: inherit;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  width: 20em;
  text-align: center;
  font-size: 1.2em;
}
.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: translateX(-50%);
}

.campaign-page .tracking-link {
  color: #000;
  margin-left: 0.3em;
}
.track-divider {
  width: 68%;
  height: 1px;
  margin-right: 1rem;
  margin-left: 1rem;
  background: #bdbdbd;
}
.add-campaign-name {
  margin: 2em 0;
}
.textfield {
  width: 100%;
}

.textfield ::placeholder {
  font-size: 1.5rem;
  font-weight: 700;
}

.description-outer-box {
  margin: 0.2em 0 5em;
}
.campaign-description {
  height: 30vh;
  margin: 0.3em 0 0.9em;
}

.campaign-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 28px;
}

.socialmedia-btn {
  width: fit-content;
  padding: 0.3em 1.2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.15s, color 0.15s, background 0.15s;
}

.lite {
  align-items: center;
  height: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #eeeeee;
  color: #212121;
}

.dark {
  background-color: #e563a4;
  color: #fff;
  margin-bottom: 2em;
}

.socialmedia-btn label {
  margin-left: 0.2em;
}
.highlight-note {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 14px;
}
.project-type-row {
  padding-bottom: 14px;
}
.note {
  font-size: 14px;
  font-weight: 400;
}
div.campaign-textarea, div.campaign-remarks {
  width: 100%;
  height: 30vh;
  border: 2px solid rgba(0, 0, 0, 0.23);
}

.campaign-textarea fieldset, .campaign-remarks fieldset {
  border: none;
}

.ql-snow .ql-tooltip {
  left: auto !important;
}

.campaign-options {
  display: flex;
  flex-direction: column;
}
.dimlight-note {
  color: #616161;
  font-size: 0.9rem;
  margin: 0;
}
.label-container {
  display: flex;
  flex-direction: row;
}
.start-date-label {
  color: #212121;
  width: fit-content;
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
}
.note-for-date {
  color: #464646;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
}
.last-date-application-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #464646;
  padding-bottom: 14px;
}
.created-by-row {
  padding: 24px 0px 0px;
}

.file-drop-area input[type="file"] {
  display: none;
}

.file-list-outer-container {
  display: flex;
  flex-direction: column;
}

.file-list-container {
  display: flex;
  flex-direction: row;
}

.file-list-container li {
  list-style-type: disclosure-closed;
  margin-left: 1.2em;
}

.selected-file {
  display: flex;
  align-items: center;
}

.selected-file .selected-file-name {
  color: #4aabed;
  margin-right: 0.2em;
  font-size: 1.1em;
}

.selected-file-label {
  margin-right: 1em;
  width: 8em;
}

.selected-file li {
  margin-right: 1.5em;
}

.file-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.remove-file {
  cursor: pointer;
}

.create-campaign-footer {
  display: flex;
  flex-direction: column;
}

.start-campaign-container {
  width: 35%;
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
}

.start-campaign-container button {
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1em;
  font-family: inherit;
  padding: 11px 24px;
}
.draft-btn {
  color: #65308F;
  border: 1px solid #65308F;
  border-radius: 30px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}
.draft-btn:hover {
  color: #421d60;
}
.choose-btn {
  border-radius: 30px;
  padding: 8px 24px;
  background-color: #65308F;
  color: #fff;
}
.choose-btn:hover {
  background-color: #421d60;
}
.choose-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}
.draft-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
  color: #fff;
  border: none;
}
.start-campaign-container .cancel-btn {
  background-color: #fff;
  border: 1px solid #114b5f;
  color: #114b5f;
}
.start-campaign-container .cancel-btn:hover {
  color: #616161;
  border: 1px solid #9e9e9e;
  background-color: #eeeeee;
}

.arrow-back {
  cursor: pointer;
}
.campaign-header-name {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  font-family: inherit;
  margin: 0 0.7em;
}

.campaign-appllication-header-name {
  color: #9c27b0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  font-family: inherit;
  margin: 0 0.7em;
}

.campaign-details-head {
  display: flex;
  align-items: center;
}

.campaign-overview-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;
}

.campaign-overview-header h1 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  font-family: inherit;
}

.adjust-alignment {
  display: flex;
  align-items: center;
}
.keywords-tooltip {
  width: 17em;
}

.missing-tags-tooltip {
  width: 13em;
}

.campaign-details-options {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 3%;
}

.campaign-details-options {
  padding: 0.25em;
  margin-right: 1em;
}

.campaign-details-options {
  padding: 0.25em 1em;
}

.campaign-data {
  margin-bottom: 3em;
}

.campaign-data span {
  font-weight: 600;
  margin-left: 0.5em;
  line-height: 2.5;
}

.description-outer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.description-content {
  margin-left: 2em;
}
.campaign-file-outer-container {
  margin-top: 1em;
}

.campaign-file-outer-container {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.campaign-file-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.campaign-file-row {
  display: flex;
  width: 100%;
}

.campaign-files-label {
  margin-right: 1em;
}

.campaign-file-name-container {
  font-weight: 600;
  padding: 0 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.2em;
  cursor: pointer;
  line-height: 2em;
  display: flex;
  align-items: center;
}

.campaign-file-name {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.campaign-tooltip {
  position: relative;
  display: inline-block;
}

.campaign-tooltip .campaign-tooltip-text {
  visibility: hidden;
  width: max-content;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #5f5a5a;
  text-align: center;
  padding: 0 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.campaign-tooltip:hover .campaign-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.social-media-data {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
}

.social-media-data .first-block,
.social-media-data .second-block {
  display: flex;
  flex-direction: row;
}

.social-media-data .last-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.data-block {
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-width: 250px;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}

.data-block-label {
  display: flex;
  color: #616161;
}

.data-count-container {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.data-block .data-icon {
  color: #979595;
}

.data-block .data-count {
  font-weight: 700;
  font-size: 1.3rem;
  margin-left: 1em;
  line-height: 1;
}

.data-block-label .beta-text {
  background: rgb(238 238 238);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1.5px 0.25rem;
  margin-left: 0.25rem;
  border-radius: 0.125rem;
}

.data-count .edit-icon {
  margin-left: 0.6rem;
  color: #333;
  font-weight: 600;
  font-size: 1.3rem;
  font-family: inherit;
  width: 1em;
}

.campaign-list-button {
  width: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.campaign-application {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  width: 100%;
}

.campaign-name-div {
  width: 50%;
}

.campaign-name-div-mobile {
  width: 100vw;
}

.campaign-application-mobile {
  align-items: start;
  flex-direction: column;
  width: 100vw;
}

.campaign-application-head {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.campaign-application-head-actions {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 50%;
  justify-content: flex-end;
}

.export-application-mobile {
  display: flex;
  gap: 20px;
  align-items: center;
}

.campaign-application-head-actions-mobile {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.no-of-creator-text {
  color: #9c27b0;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "DM Sans";
  font-style: normal;
  letter-spacing: 1px;
}

.campaign-name-text {
  color: #9c27b0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1367px) and (min-width: 1361px) {
  div.campaign-application-container {
    overflow: auto;
  }

}

@media screen and (max-width: 1360px) {
  div.campaign-page {
    width: 1067px;
  }

  div.data-loading {
    width: 75em;
  }
}

@media screen and (max-width: 768px) {
  .campaign-page h1 {
    text-align: center;
  }
  div.campaign-page {
    width: 705px;
  }

  div.campaign {
    padding: 0;
  }

  .selected-file .selected-file-name {
    font-size: 0.8em;
  }

  .middle-tabs {
    margin-right: 2em;
  }

  .campaign-list-row {
    display: flex;
    margin-top: 1em;
    flex-direction: column;
  }

  .campaign-list-row .first-element {
    width: 22em;
    margin-bottom: 1em;
  }

  .list-insta-content {
    flex-direction: column;
  }

  .insta-content-first-row {
    display: flex;
    justify-content: space-between;
  }

  .insta-content-second-row {
    display: flex;
    justify-content: space-around;
  }

  .insta-content-inner-div {
    margin-left: 0;
  }

  .insta-info-title {
    font-size: 1.1em;
  }

  .start-campaign-container {
    width: 100%;
  }

  .campaign-details {
    width: 700px;
  }

  .campaign-list-options .new-campaign {
    padding: .2rem .5rem;
  }

  .campaign-file-name-container {
    font-size: 0.9em;
  }

  .data-block {
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    min-width: 10em;
    margin-right: 0.6em;
  }

  .data-block-label {
    display: flex;
    color: #616161;
    white-space: nowrap;
    font-size: 0.8em;
  }

  .data-block-label .beta-text {
    font-size: 0.8em;
  }

  div.data-loading {
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  div.campaign-page {
    width: 535px;
  }

  div.campaign-row {
    flex-direction: column;
  }

  div.campaign-status {
    font-size: 0.7em;
  }

  .insta-info-title {
    font-size: 1em;
  }

  .campaign-details {
    width: 535px;
  }

  .campaign-file-name-container {
    font-size: 0.7em;
  }

  .social-media-data {
    display: flex;
    flex-direction: column;
  }

  .data-block {
    padding: 0.8rem;
    min-width: 14em;
    margin-right: 0;
    display: flex;
    align-items: center;
  }

  .social-media-data .first-block,
  .social-media-data .second-block {
    display: flex;
    justify-content: space-between;
  }

  .social-media-data .second-block {
    margin-top: 1em;
  }

  .social-media-data .information-badge {
    display: none;
  }
}

.view-campaign-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
