.leads-common-icons {
    display: flex;
    flex-direction: row;
}

.leads-header-filter-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.leads-table-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    column-gap: 43px;
    font-size: 17px;
    font-weight: 600;
    color: #3b4758;
}

.leads-table-mobile {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    column-gap: 43px;
    font-size: 17px;
    font-weight: 600;
    color: #3b4758;
}

.go-to-page {
    align-items: center;
    display: flex;
    column-gap: 15px;
}

.go-to-page-mobile {
    align-items: center;
    display: flex;
    column-gap: 0px;
}

.leads-alert-message {
    margin-bottom: 12px;
}

.leads-top-filter {
    padding-left: 18px;
}

.leads-search-bar {
    display: flex;
    align-items: center;
    width: 60%;
    background-color: #fff;
    height: 100%;
    padding: 7px;
    border-radius: 6px;
}

.date-range-div {
    display: flex;
    gap: 20px;
}

.date-range-col{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.date-range-footer{
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
}

.date-range-text {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 600;
    opacity: 1;
}

.leads-toolbar-actions {
    display: flex;
    justify-content: space-between;
}

.tags-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tag-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
    font-size: 16px;
    font-weight: 600;
    font-family: "DM Sans";
    text-align: left;
    letter-spacing: 0.58px;
    color: #586A84;
    opacity: 1;
}

.leads-row-text {
    font-size: 16px;
    font-weight: 600;
    font-family: "DM Sans";
    letter-spacing: 0.58px;
    color: #586A84;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* EC Leads */
.ec-leads-management-text-row {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 12px;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ec-leads-dashboard-divider {
    margin-top: 40px;
}

.ec-leads-toolbar-actions {
   display: flex;
   align-items: center;
   width: 50%;
   justify-content: flex-end;
   gap: 10px;
}

.create-lead-row-1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 6em;
    padding: 20px 0px;
}

.create-lead-row-1-mobile {
    flex-direction: column;
    gap: 15px;
}

.create-lead-col {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
}

.create-lead-col-mobile {
    width: 100%;
}

.create-lead-row-2 {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1em;
}

.create-lead-row-2-mobile {
    flex-direction: column;
    gap: 15px;
}

.proposal-sent-col-1 {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.proposal-sent-col-2 {
    width: 60%;
}

.proposal-sent-col-2-mobile {
    width: 100%;
}

.create-lead-row-3 {
    padding: 20px 0px 0px;
}

.create-lead-row-4 {
    padding: 20px 0px;
}

.create-lead-note-row {
    padding: 20px 0px 0px;
    height: 30vh;
}

.note-input-box {
    height: 20vh;
}

/* EC Table */

.ec-leads-row-text {
    font-size: 16px;
    font-weight: 600;
    font-family: "DM Sans";
    letter-spacing: 0.58px;
    color: #586A84;
    opacity: 1;
    max-width: 300px;
    white-space: normal;
    word-wrap: break-word;
}

.ec-leads-top-filter {
    padding-left: 8px;
}

/* EC Filter */
.ec-lead-filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2em;
}

.ec-lead-filter-col {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
}

.ec-lead-filter-location-row {
    padding: 15px 0px;
}

/* View EC Lead */

.view-lead-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.action-icons {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}