.platform-growth-text {
    margin: 0;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #3b4758;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-family: DM Sans;
    font-size: 1.5rem;
    font-weight: 600;
    opacity: 1;
}

.platform-growth-text-row {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-bottom: 12px;
    padding: 12px;
    align-items: center;
    border-radius: 5px;
}

.icon-background {
    width: 3.5rem;
    height: 3.5rem;
    background-color: #eff2f7;
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-dashboard-divider {
    margin-top: 50px;
}

.donut-overview-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 2em;
}

.leads-overview {
    width: 50%;
}

.creator-profile-insights {
    width: 50%;
}

.donut-overview-div-mobile {
    flex-direction: column;
}

.leads-overview-mobile {
    width: 100%;
}

.creator-profile-insights-mobile {
    width: 100%;
}
