.userlist-content {
    width: 100%;
}

.userlist-page h1 {
    color: #000;
    font-size  : 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    font-family: inherit;
}

.userlist-header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 9px;
}

.filter-account {
    display        : flex;
    margin-bottom  : 15px;
    justify-content: flex-end;

}

.no-data {
    display        : flex;
    justify-content: center;
    font-size      : x-large;
    padding        : 150px 0px;
}

.creators-list-actions-icon:hover {
    cursor: pointer;
}

.filters-content {
    display        : flex;
    justify-content: space-between;
    gap            : 10px;
    width          : 74%;
    margin-left    : auto;
}

.userslist-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.userslist-searchbar {
    align-items     : center;
    background-color: #fff;
    border-radius   : 6px;
    display         : flex;
    height          : 100%;
    padding         : 7px;
    width           : 31vw;
    margin-right    : 2em;
}

.hearder-filters {
    display: flex;
}

.userlist-table-mobile {
    align-items    : center;
    display        : flex;
    justify-content: space-between;
    flex-direction : column;
    column-gap     : 43px;
    font-size      : 17px;
    font-weight    : 600;
    color          : #3b4758;
}

.userlist-table-footer {
    align-items    : center;
    display        : flex;
    justify-content: space-between;
    column-gap     : 43px;
    font-size      : 17px;
    font-weight    : 600;
    color          : #3b4758;
    margin-top     : 1rem;
}
.userlist-filter {
    display: flex;
}
.enterprise-client-btn {
    width: 18vw;
}
.followers-range-div {
    display: flex;
    gap: 20px;
}

.followers-range-col{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.followers-range-footer{
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
}

.followers-range-text {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 600;
    opacity: 1;
}
.creators-row-text {
    letter-spacing: 0.58px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tooltip-text {
    font-size: 14px;
}
.business-name-text {
    color: #4aabed;
}
.contact-button {
    background-color: #f45ea9;
    color: #fff;
    border-radius: 15px;
    text-transform: capitalize;
    padding: 5px 15px
  }
  
  .contact-button:hover {
    background-color: #f45ea9;
    color: #fff;
  }
  .creator-header-filter-icon {
    display: flex;
    align-items: center;
}
.profile-picture {
    display: flex;
    gap: 1rem;
}
.dialog-title{
    color: #e563a4;
    font-size: 25px;
}
.dialog-content {
    font-size: 20px;
 
}
.state-city-area {
    display: flex;
    gap: 1rem;
}
/* ----------------Responsive-------------------- */
@media screen and (max-width: 1367px) and (min-width: 1361px) {}

@media screen and (max-width: 1360px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 599px) {
    .userslist-header {
        flex-direction: column;
    }
    .hearder-filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .enterprise-client-btn {
        width: 55vw;
    }
    .userslist-searchbar {
        width: 55vw;
        margin-right: 0;
    }
}
