@media screen and (max-width: 991px){
    .main-container {
        margin-bottom: 0;
        padding-bottom: 40px;
    }

    .container.hero.vertical.no-margin {
        margin-top: 20px;
        padding-right: 40px;
    }

    .container.vertical.hero {
        background-color: transparent;
    }

    .hero-wrapper {
        flex-direction: column;
        grid-template-columns: .75fr;
        display: flex;
    }

    .hero-text {
        align-items: center;
        margin-right: 0;
    }

    .landing-page-title.center, .landing-page-title.hero {
        text-align: center;
    }

    .paragraph-regular.semibold.left-aligned-mobile {
        padding-right: 0;
    }

    .paragraph-regular.testimonial, .paragraph-regular.mobile.center-text, .paragraph-regular.semibold {
        text-align: center;
    }

    .hero-image-div.landing-page-section-2-img {
        display: block;
    }

    .hero-image-div {
        display: none;
    }

    .logos-grid {
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        grid-template-rows: auto auto;
    }

    .landing-page-section-2 {
        flex-direction: column;
    }
    
    .landing-page-section-4-features-text-wrapper {
        text-align: center;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }
    
    .landing-page-section-4-heading-2 {
        text-align: center;
    }
    
    .landing-ask-us-section-heading {
        text-align: left;
    }
    
    .header-content-wrapper {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .landing-page-button {
        padding: 12px 20px;
        text-align: center;
    }
    
    .demo-button-mobile {
        border: 1px solid #00053d;
    }

    .right-container {
        display: none;
    }
    
    .container-wrapper {
        padding: 0px 20px;
    }

    .landing-page-footer-grid {
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr 1fr;
    }

    .landing-page-title {
        font-size: 42px;
    }
}
    
@media screen and (max-width: 767px) {

    .container.hero.vertical.no-margin {
        margin-top: 0;
        padding-right: 20px;
    }

    .hero-text {
        margin-right: 0;
    }

    .landing-page-title.hero {
        padding-top: 20px;
        font-size: 34px;
    }

    .container.vertical {
        align-items: stretch;
        padding-left: 20px;
        padding-right: 20px;
    }

    .landing-page-section-2-heading {
        font-size: 36px;
    }
    
    .landing-page-section-4-features-body-wrapper.landing-page-section-4-top-margin {
        display: flex;
        flex-direction: column;
    }
    
    .landing-page-section-4-heading-2 {
        font-size: 32px;
    }
    
    .landing-ask-us-section-heading {
        font-size: 24px;
    }
    
    .landing-page-button {
        text-align: center;
    }
    
    .logos-grid {
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr 1fr;
    }
    
    .right-container {
        display: none;
    }
    
    .container-wrapper {
        padding: 0px 20px;
    }
    
    .landing-page-footer-grid {
        flex-direction: column;
    }
}
    
@media screen and (max-width: 479px) {

    .landing-page-title.hero.left-aligned-mobile {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }

    .hero-text {
        margin-right: 0;
    }

    .landing-page-title.hero.left-aligned-mobile {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }

    .landing-page-title.hero {
        padding-top: 10px;
        font-size: 34px;
    }

    .paragraph-regular.semibold.left-aligned-mobile, .paragraph-regular.semibold.newsletter-text {
        text-align: left;
    }

    .hero-cta-wrapper {
        flex-direction: column;
        align-self: stretch;
    }
    .container.hero.vertical.no-margin {
        padding-top: 0;
    }

    .container.vertical.logos-container {
        margin-top: 0;
    }

    .container.vertical {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .landing-page-section-2-container.landing-page-section-2-vertical {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .landing-page-section-4 {
        padding-top: 20px;
    }
    
    .landing-page-section-4-features-body-wrapper.landing-page-section-4-top-margin {
        display: flex;
        flex-direction: column;
    }
    
    .landing-page-section-4-heading-2 {
        font-size: 28px;
    }
    
    .landing-ask-us-section-heading {
        text-align: left;
        font-size: 20px;
    }
    
    .header-content-wrapper{
        z-index: 5;
    }
    
    .landing-page-cta-wrapper {
        flex-direction: column;
        align-self: stretch;
    }
    
    .landing-page-button.primary {
        align-self: stretch;
    }
    
    .logo-grid {
        grid-column-gap: 30px;
        grid-row-gap: 50px;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr 1fr;
    }
    
    .right-container {
        display: none;
    }
    
    .container-wrapper {
        padding: 0px 20px;
    }

    .landing-page-heading-button {
        flex-direction: column;
        align-self: stretch;
        grid-row-gap: 10px;
    }
}