.closeicon {
    display: flex;
    justify-content: flex-end;
}

.close:hover {
    cursor: pointer;
    background-color: #b1b7b9;
    display: flex;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
}

.profile-popup {
    display: flex;
    gap: 24px;
    align-items: center;
}

._profileImg_k0v1p_6 {
    position: relative;
    width: 5rem;
    height: 5rem;
}

.user-profile {
    width: 5rem;
    height: 5rem;
}

._profileImg_k0v1p_6 ._pic_k0v1p_11 {
    position: relative;
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

._profileImg_k0v1p_6 ._verifiedOutline_k0v1p_28 {
    position: absolute;
    right: -5px;
    bottom: -5.5px;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #fff;
}

._profileImg_k0v1p_6 ._verified_k0v1p_21 {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    border-radius: 50%;
    --icon-color: var(--blue-600);
}

.user-fullname {
    margin-bottom: 2px;
    font-size: 1.25rem;
    font-weight: 600;
}

.insta-handle {
    color: #4aabed;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: underline;
}

.insta-icon {
    margin-top: 16px;
}

a.insta-icon-color {
    background-color: #947ebb;
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    align-items: center;
    border-radius: 10px;
}

a.insta-icon-color:hover {
    background-color: #c5bcd4;
}

.insta-icon-color {
    color: white;
    display: flex;
}

.media-icon-color {
    color: #947ebb;
}

._socialMediaButtonActive_1aohu_28 svg>path {
    fill: white;
}
._button_m08jp_1._rounded-cyan_m08jp_183>* {
    --bg: #d0dee3;
    --hoverBg: #d0dee3;
    --disabledBg: #f5f5f5;
    --bg: ##ffffff;
    --border-radius: 50%;
}
._button_m08jp_1._rounded-cyan_m08jp_183>* {
    --bg: #d0dee3;
    --hoverBg: #d0dee3;
    --disabledBg: #f5f5f5;
    --bg: ##ffffff;
    --border-radius: 50%;
}

.followers-margin-top {
    margin-top: 1.5rem;
}
.followers-border {
    padding: 1rem;
    border: 1px solid #3a0083;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    background: #ece6f3;
}
.followers-grid {
    display: flex;
    justify-content: space-evenly;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
}
.grid-content {
    display: inline-flex;
    font-size: 14px;
   
}
.profile-count {
    font-size: 1.5rem;
    font-weight: 60;
}
.info-icon-badge {
    position: relative;
    color: #212121;
    border: 1px solid #212121;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    margin: 0.3em 0 0 0.5em;
    cursor: default;
    font-weight: 700;
}
.info-icon-badge:hover{
    background-color: #212121;
    color: #fff;
}
.info-icon-badge:hover .tooltip-badge {
    display: block;
}
.tooltip-badge {
    display: none;
    text-wrap: pretty;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    font-family: inherit;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
    width: 13em;
    text-align: center;
    font-size: 1.2em;
}
.tooltip-badge::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    transform: translateX(-50%);
}
.influencer-performance {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.25rem;
}

._dynamicGrid_l3dni_44 {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 1rem;
}
._block_nmn03_2 {
    text-align: center;
}


._dynamicGrid_l3dni_44 * {
    grid-column: span 3;
}
._blockBase_1d803_5 {
    flex: 1;
    padding: 0.5rem;
    background: #ffffff;
    border: 0.0625rem solid #e0e0e0;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
}
._block_mbftb_1 {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 0.5rem;
    gap: 0.5rem;
    line-height: 1.3125rem;
    text-align: left;
}
.profile-title {
    font-weight: 600;
    line-height: 1.5rem;
}
._rowFlex_1d803_1 {
    display: flex;
}
._rowFlex_1d803_1{
    font-weight: 600;
    line-height: 1.5rem;
}
.business-details-content {
    display: flex;
    /* align-items: center; */
    gap: 0.5rem;
}
