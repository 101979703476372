.package-col {
    display       : flex;
    flex-direction: column;
    gap           : 15px;
    margin-bottom : 0.5rem;
}

.package-row {
    display       : flex;
    flex-direction: column;
    /* gap        : 2rem; */
}

.price-section {
    display: flex;
    gap    : 1rem;
}

.product-section {
    display: flex;
    gap    : 1rem;
}

.products {
    display       : flex;
    flex-direction: column;
    gap           : 0.2rem;
}

.clear-fields {
    display: flex;
    height: 3vh;
    border-radius: 15px;
    cursor: pointer;
    align-items: center;
}

.clear-fields:hover {
    background-color: rgb(247, 244, 244);
}
.circular-progress {
    display: flex;
    justify-content: center;
}