/* eslint-disable */
@font-face {
    src        : url("../font/DMSans_18pt-Regular.ttf");
    font-family: 'DMSans_18pt-Regular';
}

@font-face {
    src        : url("../font/SF Pro Text.ttf");
    font-family: 'SF Pro Text';
}


body {
    font-family: 'DMSans_18pt-Regular';
}

.no-desktop {
    display: none;
}

.mt-20 {
    margin-top: 20px;
}

.pl-5 {
    padding-left: 5px;
}

.pr-0 {
    padding-right: 0px !important;
}

.header .logo_img {
    background: #80808024;
    height    : 77px;
    padding   : 5px 25px 0px;
}

.OyeCreator_blog {
    padding-top: 40px;
}

.OyeCreator_blog .img_sec .main_heading {
    color      : #162020;
    font-size  : 55px;
    line-height: 66px;
    font-weight: 700;
}

.OyeCreator_blog .editors_picks {
    margin-top: 28px;
}

.OyeCreator_blog .img_sec .heading {
    color      : #000;
    font-size  : 16px;
    line-height: 24px;
    font-weight: 700;
}

.OyeCreator_blog .img_sec img {
    width: 100%;
}

.OyeCreator_blog .img_sec .btn_sec {
    margin-top: 10px;
}

.influencer_btn {
    background-color: #E563A4;
    color           : #fff;
    font-size       : 12px;
    line-height     : 21px;
    font-weight     : 500;
    border          : none;
    border-radius   : 50px;
    padding         : 2px 15px;
    width           : 100%;
}

.all_btn {
    background-color: #E563A4;
    color           : #fff;
    font-size       : 12px;
    line-height     : 21px;
    font-weight     : 500;
    border          : none;
    border-radius   : 50px;
    padding         : 2px 15px;
    width           : 100%;
}

.OyeCreator_blog .img_sec .post_heading {
    color      : #162020;
    font-size  : 39px;
    line-height: 49px;
    font-weight: 700;
    margin-top : 20px;
}

.OyeCreator_blog .content {
    font-size    : 14px;
    font-weight  : 400;
    line-height  : 21px;
    color        : #162020;
    margin-bottom: 0px;
    margin-top   : 15px;
}

.OyeCreator_blog .content span {
    font-size  : 14px;
    font-weight: 700;
    line-height: 21px;
    color      : #205BC3;
}

.blog_info .bootcamp_Img img {
    width: 100%;
}

.blog_info .editors_picks .heading {
    font-size  : 16px;
    font-weight: 700;
    line-height: 24px;
    color      : #000;
}

.blog_info .bootcamp_info .content1 {
    font-size  : 18px;
    font-weight: 700;
    line-height: 27px;
    color      : #162020;
    padding-top: 15px;
}

.latest_Section .details .heading {
    font-size  : 32px;
    line-height: 38px;
    font-weight: 700;
    color      : #000;
}

.view_allBtn {
    font-size  : 16px;
    line-height: 24px;
    font-weight: 700;
    color      : #205BC3;
    background : transparent;
    border     : none;
}

.latest_Section .details .view_allBtn img {
    padding-left: 5px;
}

.latest_Section .latest_ImagesInfo .img_deatils .content,
.featured_resources .latest_ImagesInfo .img_deatils .content,
.guides_section .latest_ImagesInfo .img_deatils .content,
.data_reports .latest_ImagesInfo .img_deatils .content,
.marketing_insights .latest_ImagesInfo .img_deatils .content {
    font-size    : 18px;
    line-height  : 27px;
    font-weight  : 800;
    color        : #162020;
    /* min-height: 81px; */
}

.latest_Section .latest_ImagesInfo .btn_sec,
.featured_resources .latest_ImagesInfo .btn_sec,
.guides_section .latest_ImagesInfo .btn_sec,
.data_reports .latest_ImagesInfo .btn_sec,
.marketing_insights .latest_ImagesInfo .btn_sec {
    margin-top: 20px;
}

.latest_Section .latest_ImagesInfo .img_deatils .date_info {
    font-size  : 14px;
    line-height: 20px;
    font-weight: 400;
    color      : #162020;
}

.latest_Section .latest_ImagesInfo .img_deatils .date_info img {
    padding: 0px 7px;
}

.latest_Section .latest_ImagesInfo .img_deatils .blogImg,
.featured_resources .latest_ImagesInfo .img_deatils .blogImg,
.guides_section .latest_ImagesInfo .img_deatils .blogImg,
.data_reports .latest_ImagesInfo .img_deatils .blogImg,
.marketing_insights .latest_ImagesInfo .img_deatils .blogImg,
.socialMedia_analytics .latest_ImagesInfo .img_deatils .blogImg {
    width        : 100%;
    border-radius: 6px;
}

.featured_resources,
.guides_section,
.data_reports,
.marketing_insights,
.topic_hubs,
.socialMedia_analytics {
    padding-top: 30px;
}

.featured_resources .details .heading,
.guides_section .details .heading,
.data_reports .details .heading,
.marketing_insights .details .heading,
.socialMedia_analytics .details .heading {
    font-size  : 32px;
    line-height: 38px;
    font-weight: 700;
    color      : #000;
}

.topic_hubs .heading {
    font-size  : 32px;
    line-height: 38px;
    font-weight: 700;
    color      : #000;
}

.topic_hubs .link_names p {
    font-size  : 18px;
    line-height: 27px;
    font-weight: 700;
    color      : #205BC3;
}

.buildGrow_section {
    background-color: #e563a41c;
    padding         : 60px 0px 50px;
    margin-top      : 40px;
}

.buildGrow_section .img-sec img {
    width: 100%;
}

.buildGrow_section .main_heading {
    font-size  : 32px;
    line-height: 40px;
    font-weight: 700;
    color      : #162020;
    margin-top : 15px;
}

.buildGrow_section .info {
    font-size  : 16px;
    line-height: 24px;
    font-weight: 400;
    color      : #162020;
}

.buildGrow_section .sprout_btn {
    background-color: #E563A4;
    color           : #fff;
    border          : none;
    font-size       : 18px;
    line-height     : 18px;
    font-weight     : 700;
    padding         : 10px 20px;
    border-radius   : 6px;
}

.buildGrow_section .content {
    padding-left : 60px;
    padding-right: 40px;
}

.socialMedia_analytics .content {
    font-size  : 20px;
    line-height: 28px;
    font-weight: 800;
    color      : #162020;
}

.socialMedia_analytics .info {
    font-size  : 16px;
    line-height: 24px;
    font-weight: 400;
    color      : #162020;
}

.socialMedia_analytics .date_info {
    font-size  : 14px;
    line-height: 20px;
    font-weight: 400;
    color      : #162020;
}

.socialMedia_analytics .date_info img {
    padding: 0px 7px;
}

.socialMedia_analytics .bootcamp_Img img {
    width: 100%;
}

.socialMedia_analytics .bootcamp_info .content1 {
    font-size  : 16px;
    font-weight: 700;
    line-height: 24px;
    color      : #162020;
    padding-top: 10px;
}

/*category view all page*/
.CategorySection .CategoryDeatils .heading .back_btn {
    font-size      : 14px;
    line-height    : 21px;
    font-weight    : 600;
    color          : #000;
    text-decoration: none;
}

.CategorySection .CategoryDeatils .heading a {
    font-size      : 14px;
    line-height    : 21px;
    font-weight    : 400;
    color          : #717171;
    text-decoration: none;
}

/*blog details page*/
.BlogsDetails .BlogDetailsSection .MainHeading {
    font-size  : 55px;
    line-height: 60px;
    font-weight: 700;
    color      : #000;
    padding    : 30px 90px;
    margin     : 0px 42px;
}

.BlogsDetails .BlogDetailsSection .content {
    font-size  : 20px;
    line-height: 34px;
    font-weight: 400;
    color      : #000;
    padding    : 0px 98px;
    margin     : 0px 96px;
}

.BlogsDetails .BlogDetailsSection .WebinarImg img {
    width: 100%;
}

.BlogsDetails .ButtonSection {
    background-color: #FFDAED;
    padding         : 50px;
}

.BlogsDetails .ButtonSection p {
    font-size  : 28px;
    font-weight: 800;
    line-height: 42px;
    text-align : center;
    color      : #000000;
    padding    : 0px 93px 15px;
    margin     : 0px 95px;
}

.BlogsDetails .ButtonSection .ClickBtn {
    background   : #E563A4;
    border       : none;
    border-radius: 8px;
    font-size    : 18px;
    line-height  : 27px;
    font-weight  : 700;
    color        : #fff;
    padding      : 3px 20px;
}

.BlogsDetails .ReadNextSection {
    padding: 50px 0px;
}

.BlogsDetails .ReadNextSection .ReadNextDetails .MainHeading {
    font-size    : 44px;
    line-height  : 47px;
    font-weight  : 800;
    text-align   : center;
    margin-bottom: 40px;
    color        : #000;
}

.BlogsDetails .ReadNextSection .ReadNextDetails .ImgHeading {
    font-size  : 18px;
    line-height: 27px;
    font-weight: 800;
    text-align : center;
    color      : #162020;
    padding    : 10px 24px;
}

.BorderSection img {
    margin-top: -36px;
}

.SocialMedia_Section {
    padding: 60px 0px;
}

.SocialMedia_Section .SocialMediaIcons {
    border    : 1px solid #EAEAEA;
    box-shadow: 0px 1px 4px 0px #00000014;
    padding   : 20px 0px;
    margin    : 0px 150px;
}

.SocialMedia_Section .SocialMediaIcons p {
    font-size     : 16px;
    line-height   : 25px;
    font-weight   : 500;
    color         : #000;
    margin-bottom : 0px;
    /* font-family: 'SF Pro Text'; */
}

.SocialMedia_Section .SocialMediaIcons img {
    padding-right: 10px;
    margin-top   : -4px;
}

.SocialMedia_Section .TwitterIcn {
    position: relative;
}

.SocialMedia_Section .TwitterIcn::before {
    content         : '';
    position        : absolute;
    background-color: #EAEAEA;
    width           : 1px;
    height          : 66px;
    bottom          : -20px;
    left            : -1px;
}

.BlogsDetails .SessionSection .name {
    margin-top: 5px;
}

.BlogsDetails .SessionSection .name .UserName {
    font-size  : 16px;
    line-height: 25px;
    font-weight: 800;
}

.BlogsDetails .SessionSection .name .Date {
    font-size  : 16px;
    line-height: 20px;
    font-weight: 400;
}

.BlogsDetails .SessionContent .content {
    font-size  : 18px;
    line-height: 30px;
    font-weight: 400;
    color      : #162020;
}

.BlogsDetails .SessionContent .content span {
    font-weight: 600;
}

.BlogsDetails .SessionContent .MoreInfo {
    font-weight: 800;
    font-size  : 18px;
    line-height: 30px;
    color      : #162020;
}

.BlogsDetails .SessionSection .SocialIcons .ImgDiv {
    position: relative;
    padding : 0px 20px;
    display : inline-block;
}

.SessionSection .SocialIcons .BeforeImgDiv {
    position: relative;
    padding : 0px 20px;
    display : inline-block;
}

.BlogsDetails .SessionSection .SocialIcons .BeforeImgDiv::before {
    content         : '';
    position        : absolute;
    background-color: #EAEAEA;
    width           : 1px;
    height          : 45px;
    bottom          : -10px;
    left            : -1px;
}

.BlogsDetails .SessionSection .SocialIcons {
    border    : 1px solid #EAEAEA;
    box-shadow: 0px 1px 4px 0px #00000014;
    padding   : 10px 0px;
    text-align: center;
}

.BlogsDetails .SessionSection .SessionInfo {
    padding: 50px 70px;
}

/*header*/
.logo {
    position: absolute;
    left    : 20px;
    width   : 100px;
    cursor  : pointer;
}

.PricingDetails li,
.PricingDetails a,
.PricingDetails button {
    font-weight    : 600;
    font-size      : 16px;
    color          : #56606E;
    text-decoration: none;
    line-height    : 30px;
}

.PricingDetails button {
    color: #fff;
}

/* li,
a,
button {
    font-weight    : 600;
    font-size      : 16px;
    color          : #56606E;
    text-decoration: none;
    line-height    : 30px;
} */

.PricingDetails header {
    background     : #80808024;
    height         : 77px;
    padding        : 0px 25px;
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
}

.navlinks li {
    display    : inline-block;
    margin     : 30px;
    align-items: center;
}

.PricingDetails a:hover {
    color: #56606E;
}

.TryBtn {
    width           : 100%;
    padding         : 4px 16px;
    background-color: #E563A4;
    border-radius   : 50px;
    font-size       : 12px;
    color           : #fff;
    outline         : none;
    cursor          : pointer;
    transition      : all 0.5s, ease 0s;
    border          : none;
    z-index         : 999;
}

.TryBtn:hover {
    box-shadow: 1px 1px 5px black;
}

.DemoBtn {
    width           : 100%;
    padding         : 4px 16px;
    background-color: #fff;
    border-radius   : 50px;
    font-size       : 12px;
    color           : #56606E;
    outline         : none;
    cursor          : pointer;
    transition      : all 0.5s, ease 0s;
    border          : none;
    z-index         : 999;
}

.DemoBtn:hover {
    box-shadow: 1px 1px 5px black;
}

.sidebar {
    position        : fixed;
    top             : 0;
    right           : 0;
    height          : 100vh;
    width           : 300px;
    z-index         : 999;
    background-color: transparent;
    backdrop-filter : blur(10px);
    box-shadow      : -3px 0 10px black;
    display         : none;
    justify-content : flex-start;
    list-style      : none;
    flex-direction  : column;
    transition      : transform 0.3s ease;
    transform       : translateX(100%);
}

.sidebar li {
    width      : 100%;
    margin     : 25px;
    text-shadow: 1px 1px 2px black;

}

.sidebar img {
    position: absolute;
    bottom  : 20px;
    width   : 100%;
    height  : 120px;
}

nav ion-icon {
    font-size: 2em;
    position : absolute;
    top      : 35px;
    right    : 20px;
}

/* ul {
    padding-left: 9rem !important;
} */
@media only screen and (min-width: 830px) {
    .navlinks ion-icon {
        display: none;

    }
}

@media only screen and (max-width: 830px) {
    .navlinks .items {
        display: none;
    }
}

/*footer*/
.footer {
    background-color: #00053d;
    padding         : 50px 0px;
}

.footer .links .heading {
    font-size  : 14px;
    font-weight: 600;
    color      : #fff;
}

.footer .links .content {
    color          : #606386;
    font-weight    : 600;
    font-size      : 14px;
    text-decoration: underline;
}

.footer .links .content1 {
    color      : #606386;
    font-weight: 600;
    font-size  : 14px;
}

.footer .links .address {
    color      : #606386;
    font-weight: 500;
    font-size  : 14px;
}

/*PricingDetails*/
.PricingDetails .QuestionsSection {
    padding: 40px 0px 100px;
}

.PricingDetails .QuestionsSection .MainHeading {
    font-size    : 30px;
    line-height  : 42px;
    font-weight  : 400;
    text-align   : center;
    color        : #1B1C1E;
    margin-bottom: 30px;
}

.PricingDetails .QuestionsSection .accordion-button:not(.collapsed) {
    font-size       : 16px;
    line-height     : 30px;
    font-weight     : 400;
    color           : #1B1C1E;
    background-color: #E8F0FD;
    margin-bottom   : 0px;
    border-radius   : 8px 8px 0px 0px;
}

.PricingDetails .QuestionsSection .accordion-body {
    font-size       : 16px;
    line-height     : 30px;
    font-weight     : 400;
    background-color: #F4F8FF;
    color           : #1B1C1E;
    border-radius   : 0px 0px 8px 8px;
}

.PricingDetails .QuestionsSection .accordion {
    border                     : none;
    --bs-accordion-border-color: transparent !important;
}

.PricingDetails .QuestionsSection .accordion-button {
    background-color: #E8F0FD;
    font-size       : 16px;
    line-height     : 30px;
    font-weight     : 400;
    color           : #1B1C1E;
    border-radius   : 8px;
}

.PricingDetails .QuestionsSection .accordion-button:not(.collapsed):focus {
    box-shadow: none !important;
}

.PricingDetails .TrustedOrganizations {
    background-color: #F4F8FF;
    padding         : 50px 0px;
}

.PricingDetails .TrustedOrganizations .OrganizationInfo {
    background-color: #FFF;
    padding         : 50px 60px;
}

.PricingDetails .TrustedOrganizations .OrganizationInfo img {
    width: 100%;
}

.PricingDetails .TrustedOrganizations .MainHeading {
    font-size  : 30px;
    line-height: 50px;
    font-weight: 500;
    text-align : center;
    color      : #292929;
}

.PricingDetails .ContactUs {
    background-color   : #0F67EA;
    background-position: right;
    background-repeat  : no-repeat;
    background-size    : contain;
    height             : 340px;
    width              : 100%;
    padding            : 95px 0px;
    margin-bottom      : 15px;
}

.PricingDetails .ContactUs p {
    font-size  : 28px;
    line-height: 40px;
    font-weight: 400;
    color      : #fff;
    padding    : 0px 15px 10px 0px;
}

.PricingDetails .ContactUs .ContactBtn {
    color           : #0F67EA;
    font-size       : 16px;
    line-height     : 28px;
    background-color: #fff;
    font-weight     : 700;
    border-radius   : 8px;
    border          : none;
    padding         : 5px 25px;
    margin-bottom   : 10px;
}

.PricingDetails .CreatorSection {
    padding: 40px 0px;
}

.PricingDetails .CreatorSection .MainHeading {
    font-size  : 38px;
    line-height: 56px;
    font-weight: 800;
    text-align : center;
    padding    : 0px 95px;
    margin     : 0px 40px 25px;
}

.PricingDetails .CreatorSection .content {
    font-size  : 18px;
    line-height: 27px;
    font-weight: 400;
    text-align : center;
    color      : #6A6A6A;
    margin     : 0px 54px 20px;
    padding    : 0px 85px;
}

.PricingDetails {
    padding-top: 1px;

}

.PricingDetails .CreatorSection .CreatorDetails {
    padding: 40px 0px 0px;
}

.PricingDetails .CreatorSection .StarImg {
    margin-bottom: -18%;
}

.PricingDetails .CreatorSection .StarImg img {
    width: 12%;
}

.PricingDetails .GoodplansSection {
    background-color: #f4f8ff;
    padding         : 50px 0px;
    margin-top      : 40px;
}

.PricingDetails .GoodplansSection .ModelInfo {
    background-color   : #087113;
    height             : 566px;
    border-radius      : 16px;
    padding            : 20px 0px 0px 20px;
    background-repeat  : no-repeat;
    background-position: bottom;
}

.PricingDetails .GoodplansSection .ModelInfo .MainHeading {
    font-size  : 34px;
    line-height: 44px;
    font-weight: 700;
    color      : #fff;
}

.PricingDetails .GoodplansSection .ModelInfo:hover {
    border: 4px solid #21D733
}

.PricingDetails .GoodplansSection .ModelInfo .content {
    font-size  : 20px;
    line-height: 26px;
    font-weight: 500;
    color      : #fff;
}

.PricingDetails .GoodplansSection .ModelInfo1 {
    background-color: #fff;
    height          : 566px;
    border-radius   : 16px;
    padding         : 20px;
}

.PricingDetails .GoodplansSection .ModelInfo1:hover {
    border: 2px solid #F06E04
}

.PricingDetails .GoodplansSection .ModelInfo1 .MainHeading {
    color      : #3C3C3C;
    font-size  : 24px;
    line-height: 28px;
    font-weight: 700;
}

.PricingDetails .GoodplansSection .ModelInfo1 .content {
    color      : #6A6A6A;
    font-size  : 14px;
    line-height: 21px;
    font-weight: 400;
    min-height : 80px;
}

.PricingDetails .GoodplansSection .ModelInfo1 .TryBtn {
    background-color: #0F67EA;
    border-radius   : 10px;
    padding         : 10px 0px;
    font-size       : 20px;
    line-height     : 28px;
    font-weight     : 600;
}

.PricingDetails .GoodplansSection .CreatorCount p {
    font-size    : 16px;
    line-height  : 30px;
    font-weight  : 400;
    color        : #000;
    margin-bottom: 0px;
}

.PricingDetails .GoodplansSection .CreatorCount {
    padding-top: 20px;
    font-size  : 14px;
}

.PricingDetails .GoodplansSection .CreatorCount p img {
    padding-right: 8px;
}

.no-credit-margin {
    padding: 36px 0px 0px 0px;
}

.PricingDetails .GoodplansSection .InfoLink {
    color      : #0C4293;
    font-size  : 12px;
    line-height: 28px;
    font-weight: 400;
    text-align : center;
    margin     : 20px 0px;
}

.PricingDetails .GoodplansSection .ModelInfo1 .onHoverDiv {
    display     : none;
    margin-top  : -30px;
    margin-right: -26px;
}

.PricingDetails .GoodplansSection .ModelInfo1:hover .onHoverDiv {
    display: block;
}

/* .disabled {
    pointer-events: none;
} */
.PricingDetails .FeaturesSection {
    padding: 20px 0px 40px;
}

.PricingDetails .FeaturesSection table th {
    font-size  : 20px;
    line-height: 32px;
    font-weight: 700;
    color      : #1B1C1E;
}

.PricingDetails .FeaturesSection table td {
    font-size  : 16px;
    line-height: 28px;
    font-weight: 400;
    color      : #000;
}

.PricingDetails .FeaturesSection .table> :not(caption)>*>* {
    padding: 10px 10px;
}

.PricingDetails .FeaturesSection table {
    border: 1px solid #E6E6E6;
}

.PricingDetails .FeaturesSection .TryForFreeBtn {
    border          : 2px solid #0F67EA;
    background-color: #fff;
    font-size       : 14px;
    line-height     : 28px;
    font-weight     : 700;
    color           : #0F67E9;
    padding         : 0px 22px;
}

.PricingDetails .FeaturesSection .NoCredit td {
    font-size     : 12px;
    line-height   : 15px;
    font-weight   : 400;
    color         : #1B1C1E;
    padding-top   : 0px !important;
    padding-bottom: 20px !important;
}

/*tabbing UI*/
.PricingDetails .TabbingSection .nav-tabs {
    width           : 50%;
    margin          : 0 auto;
    border-bottom   : 0px;
    border          : 1px solid #EAF1FF;
    border-radius   : 50px;
    justify-content : center;
    background-color: #f6f6f666;
}

.PricingDetails .TabbingSection .nav-tabs .nav-link.active {
    color        : #0F67EA;
    font-size    : 20px;
    line-height  : 30px;
    font-weight  : 600;
    border       : 1px solid #ECF4FF;
    border-radius: 50px;
    box-shadow   : 6px 0px 12px 0px #EEEEEE4D;
    width        : 50%;
}

.PricingDetails .TabbingSection .nav-tabs .nav-link {
    color      : #56606E;
    font-size  : 20px;
    line-height: 30px;
    font-weight: 600;
    width      : 50%;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: transparent;
}

.FeaturesSection .accordion-button:not(.collapsed) {
    color           : #000;
    background-color: transparent;
    box-shadow      : none;
    padding         : 0px 0px 15px;
    justify-content : center;
    font-size       : 30px;
    line-height     : 28px;
    font-weight     : 400;
}

.FeaturesSection .accordion {
    --bs-accordion-border-color: transparent;
}

.FeaturesSection .accordion-body {
    padding: 10px 0px 0px;
}

.FeaturesSection .accordion-button::after {
    margin-left: unset;
    width      : 32px;
}

.FeaturesSection .accordion-button:focus {
    border-color: transparent;
    box-shadow  : none;
}

.FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
    justify-content: center;
    font-size      : 18px;
    line-height    : 28px;
    font-weight    : 400;
    padding        : 0px 0px 15px;
}

.FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed::after {
    margin-left: 12px;
}

.PricingDetails .GoodplansSection .ReviewInfo .MainHeading {
    color        : #3C3C3C;
    font-size    : 24px;
    line-height  : 28px;
    font-weight  : 700;
    margin-bottom: 0px;
}

.PricingDetails .GoodplansSection .ReviewInfo p {
    margin-bottom: 15px;
}

.PricingDetails .GoodplansSection .ReviewInfo img {
    padding-top: 6px;
}

.accordion-button {
    margin-top: 20px !important;
}

.accordion-button:focus {
    border-color: transparent;
    box-shadow  : none;
}

.minHeightCss {
    min-height: 30px;
}

.accordion-item {
    width: 85vw;
}

.active-popular {
    border: 2px solid #F06E04;
}

.popularimg.text-end {
    margin-top: -2rem;
    display   : block;
}

.FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
    color           : #000;
    background-color: transparent;
    box-shadow      : none;
    padding         : 0px 0px 15px;
    justify-content : center;
    font-size       : 30px;
    line-height     : 28px;
    font-weight     : 400;
}

.creator-collab {
    margin-top: 2rem;
}

.logo-images {
    width: 8vw;
}

.logo-images-logo {
    width: 4vw;
}

.logo-business {
    display    : flex;
    gap        : 2rem;
    align-items: center;
}

.nocredit-for-creators {
    padding: 72px 0px;
}

.row-creators {
    display     : flex;
    gap         : 1.5rem;
    margin-right: 3rem;
}

/* ----------------------pricing responsive------------------------------------ */
@media (min-width: 320px) and (max-width: 479px) {
    .PricingDetails .CreatorSection .MainHeading {
        font-size  : 28px;
        line-height: 36px;
        font-weight: 700;
        padding    : 0px 0px;
        margin     : 0px;
    }

    .PricingDetails .CreatorSection .StarImg img {
        width: 12%;
    }

    .PricingDetails {
        padding-top: 1px;

    }

    .PricingDetails .CreatorSection .content {
        font-size  : 14px;
        line-height: 21px;
        font-weight: 400;
        margin     : 0px;
        padding    : 20px 0px;
    }

    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }

    .PricingDetails .TabbingSection .nav-tabs .nav-link.active {
        font-size  : 14px;
        line-height: 21px;
    }

    .PricingDetails .TabbingSection .nav-tabs .nav-link {
        font-size  : 14px;
        line-height: 21px;
    }

    .PricingDetails .ContactUs p {
        font-size  : 24px;
        line-height: 38px;
        padding    : 20px 0px;
    }

    .PricingDetails .ContactUs {
        padding: 0px 0px;
        height : unset;
    }

    .PricingDetails .TrustedOrganizations .MainHeading {
        font-size  : 16px;
        line-height: 24px;
    }

    .PricingDetails .TrustedOrganizations .OrganizationInfo {
        padding: 25px 40px;
    }

    .PricingDetails .QuestionsSection .MainHeading {
        font-size  : 20px;
        line-height: 24px;
        text-align : left;
    }

    .PricingDetails .FeaturesSection .TryForFreeBtn {
        padding: 1px 9px;
    }

    .PricingDetails .FeaturesSection .table> :not(caption)>*>* {
        padding: 10px 10px;
    }

    .FeaturesSection .accordion-item:last-of-type .accordion-button.collapsed {
        font-size  : 14px;
        line-height: 16px;
    }

    .FeaturesSection .accordion-button:not(.collapsed) {
        font-size  : 14px;
        line-height: 16px;
    }

    .PricingDetails .CreatorSection .CreatorDetails {
        padding: 0px 0px 0px;
    }

    .pricing-logo-business-mobile-view {
        display        : flex;
        justify-content: center;
        align-items    : center;
        gap            : 2rem;
    }

    .pricing-logo-images {
        width: 16vw;
    }

    .pricing-logo-images-logo {
        width: 9vw;
    }
    .pricing-setu-logo {
        width: 16vw;
    }

    .row-creators {
        display       : flex;
        flex-direction: column;
        gap           : 0rem;
        margin-right  : 0rem;
    }
    .pricing-logo-set {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .PricingDetails .CreatorSection .MainHeading {
        font-size  : 30px;
        line-height: 38px;
        font-weight: 700;
        padding    : 0px 90px;
        margin     : 0px 30px;
    }

    .PricingDetails .CreatorSection .content {
        font-size  : 20px;
        line-height: 23px;
        font-weight: 400;
        margin     : 0px;
        padding    : 20px 60px;
    }

    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }

    .PricingDetails .FeaturesSection .TryForFreeBtn {
        padding: 0px 21px;
    }

    .PricingDetails .FeaturesSection .FeaturesDetails table td {
        font-size: 12px;
    }

    .PricingDetails .ContactUs {
        height: unset;
    }

    .PricingDetails .ContactUs img {
        width      : 100%;
        margin-top : -150px;
        margin-left: 37px;
    }

    .PricingDetails .QuestionsSection .MainHeading {
        text-align: left;
    }

    .landing-page-footer-grid {
        grid-column-gap: 14px;
    }
    .row-creators {
        display       : flex;
        flex-direction: column;
        gap           : 0rem;
        margin-right  : 0rem;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .PricingDetails .CreatorSection .MainHeading {
        font-size  : 28px;
        line-height: 36px;
        font-weight: 700;
        padding    : 0px;
        margin     : 0px;
    }

    .PricingDetails .CreatorSection .content {
        font-size  : 16px;
        line-height: 23px;
        font-weight: 400;
        margin     : 0px;
        padding    : 20px 0px;
    }

    .PricingDetails .TabbingSection .nav-tabs {
        width: 95%;
    }

    .PricingDetails .FeaturesSection .table> :not(caption)>*>* {
        padding: 10px 12px;
    }

    .PricingDetails .ContactUs {
        padding: 0px 0px;
        height : unset;
    }

    .PricingDetails .ContactUs p {
        font-size  : 24px;
        line-height: 38px;
        padding    : 20px 5px;
    }

    .PricingDetails .TrustedOrganizations .MainHeading {
        font-size  : 20px;
        line-height: 32px;
    }

    .PricingDetails .QuestionsSection .MainHeading {
        font-size  : 24px;
        line-height: 26px;
        text-align : left;
    }

}

@media (min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1250px;
    }
}